import React from "react";
import { ResultBoard } from "./styled";
import { Heading, Box, Flex, Progress, Tooltip } from "@chakra-ui/react";
import Medal from "../../users/icons/medal";
import Team from "../../users/icons/team";
// import Level1 from "../../users/icons/level1";
// import Level2 from "../../users/icons/level2";
// import Level3 from "../../users/icons/level3";
// import Level4 from "../../users/icons/level4";

const Results = ({ points }) => {
  const getLevelName = () => {
    if (
      points &&
      points.totalIndividualPoints &&
      points.totalIndividualPoints > 0
    ) {
      let levelName = "Newbie";
      if (points.totalIndividualPoints >= 150) {
        levelName = "World Class";
      } else if (points.totalIndividualPoints >= 100) {
        levelName = "Professional";
      } else if (points.totalIndividualPoints >= 50) {
        levelName = "Amateur";
      } else if (points.totalIndividualPoints >= 0) {
        levelName = "Newbie";
      }

      return levelName;
    }
    return "Newbie";
  };

  const getNexLevelPoints = () => {
    if (
      points &&
      points.totalIndividualPoints &&
      points.totalIndividualPoints > 0
    ) {
      let nextLevelPoint = 50;

      if (points.totalIndividualPoints >= 150) {
        nextLevelPoint = 200 - points.totalIndividualPoints;
      } else if (points.totalIndividualPoints >= 100) {
        nextLevelPoint = 150 - points.totalIndividualPoints;
      } else if (points.totalIndividualPoints >= 50) {
        nextLevelPoint = 100 - points.totalIndividualPoints;
      } else if (points.totalIndividualPoints >= 0) {
        nextLevelPoint = 50 - points.totalIndividualPoints;
      }

      return nextLevelPoint;
    }
    return 0;
  };

  const getLevelIcon = () => {
    if (
      points &&
      points.totalIndividualPoints &&
      points.totalIndividualPoints > 0
    ) {
      if (points.totalIndividualPoints >= 150) {
        return (
          <Box
            width={"100px"}
            height={"100px"}
            backgroundImage={"url(/images/level4.png)"}
            backgroundRepeat={"no-repeat"}
            backgroundPosition={"center"}
            backgroundSize={"contain"}
          />
        );
      } else if (points.totalIndividualPoints >= 100) {
        return (
          <Box
            width={"100px"}
            height={"100px"}
            backgroundRepeat={"no-repeat"}
            backgroundPosition={"center"}
            backgroundImage={"url(/images/level3.png)"}
            backgroundSize={"contain"}
          />
        );
      } else if (points.totalIndividualPoints >= 50) {
        return (
          <Box
            width={"100px"}
            height={"100px"}
            backgroundRepeat={"no-repeat"}
            backgroundPosition={"center"}
            backgroundImage={"url(/images/level2.png)"}
            backgroundSize={"contain"}
          />
        );
      } else if (points.totalIndividualPoints >= 0) {
        return (
          <Box
            width={"100px"}
            height={"100px"}
            backgroundRepeat={"no-repeat"}
            backgroundPosition={"center"}
            backgroundImage={"url(/images/level1.png)"}
            backgroundSize={"contain"}
          />
        );
      }
    }
    return (
      <Box
        width={"100px"}
        height={"100px"}
        backgroundImage={"url(/images/level1.png)"}
        backgroundSize={"contain"}
      />
    );
  };
  const getCurrentLevelProgress = () => {
    if (
      points &&
      points.totalIndividualPoints &&
      points.totalIndividualPoints > 0
    ) {
      let totalRankPoints = 0;

      if (points.totalIndividualPoints >= 150) {
        totalRankPoints = 200;
      } else if (points.totalIndividualPoints >= 100) {
        totalRankPoints = 150;
      } else if (points.totalIndividualPoints >= 50) {
        totalRankPoints = 100;
      }

      return (totalRankPoints * 100) / 50;
    }
    return 0;
  };

  return (
    <>
      <ResultBoard width={100}>
        <Flex
          justifyContent={"space-between"}
          position={"relative"}
          padding={"0 "}
        >
          <Box width={"10%"}>
            {/* <Box position={"absolute"} top={"-1.4em"} left={"-.5em"}> */}
            <Tooltip label={`${getLevelName()}`}>
              <Box
                position={"absolute"}
                top={{ base: "-30px" }}
                left={{ base: "-68px" }}
                // background={"black"}
                width={{ base: "100px" }}
                height={{ base: "100px" }}
                borderRadius={"50%"}
                // border={"5px white solid"}
              >
                {getLevelIcon()}
              </Box>
            </Tooltip>
            {/* </Box> */}
          </Box>
          <Box width={"80%"}>
            <Flex>
              <Flex mr={{ base: "0", md: ".8em", xl: "0" }}>
                <Heading
                  fontSize={"1.7em"}
                  color={"black"}
                  lineHeight={1}
                  mb={"0.3em"}
                >
                  <Box
                    display={{
                      base: "none",
                      sm: "inline",
                      md: "none",
                      xl: "inline"
                    }}
                    opacity={"0.6"}
                    fontSize={".8em"}
                  >
                    you've got
                  </Box>{" "}
                  {points.totalIndividualPoints.toFixed(1)}
                </Heading>{" "}
                <Tooltip label={`Individual Timelog Points`}>
                  <Box display={"inline-block"}>
                    <Medal height={"30px"} width={"30px"} />
                  </Box>
                </Tooltip>
              </Flex>
              <Flex>
                <Heading
                  fontSize={"1.7em"}
                  color={"black"}
                  lineHeight={1}
                  mb={"0.3em"}
                >
                  <Box
                    display={{
                      base: "none",
                      sm: "inline",
                      md: "none",
                      xl: "inline"
                    }}
                    opacity={"0.6"}
                    fontSize={".8em"}
                  >
                    and
                  </Box>{" "}
                  {points.totalTeamPoints.toFixed(1)}{" "}
                </Heading>{" "}
                <Tooltip label={`Completed Task`}>
                  <Box display={"inline-block"}>
                    <Team height={"30px"} width={"30px"} />
                  </Box>
                </Tooltip>
              </Flex>
            </Flex>
            <Tooltip
              label={`Next level reached in ${(
                getNexLevelPoints() || 0
              ).toFixed(1)} points`}
            >
              <Box>
                <Progress
                  value={getCurrentLevelProgress()}
                  borderRadius={"1em"}
                  colorScheme={"red"}
                />
              </Box>
            </Tooltip>
          </Box>
        </Flex>
      </ResultBoard>
    </>
  );
};

export default Results;
