import { createGlobalState } from "react-hooks-global-state";
import { presistData } from "./util";

let thisState;
//lets load user data for reload
if (typeof localStorage !== "undefined" && localStorage.betclicApp) {
  thisState = JSON.parse(localStorage.betclicApp);
}

const initialState = {
  color: {
    primary: "#101820",
    secondary: "#E30014"
  },
  pageClass: "login",
  userTypes: [],
  users: [],
  news: [],
  task: [],
  timeLog: [],
  calendar: [],
  offsets: [],
  requests: [],
  points: {
    totalPoints: 0,
    totalTeamPoints: 0,
    totalIndividualPoints: 0
  },
  connectionError: false,
  clients: [],
  userHistory: [],
  paymentToken: false,
  socket: null,
  remoteStated: false,
  darkMode: false,
  editProfile: false,
  loggedIn: false,
  setLoggedIn: null,
  viewOptions: {
    date: new Date(),
    displayType: "week", // week||day
    viewType: "global",
    showApproval: false,
    type: false,
    search: false
  },
  ...thisState
};

const { useGlobalState, setGlobalState, getGlobalState } = createGlobalState(
  initialState
);

const setStorageState = (type, data) => {
  presistData("betclicApp", { ...thisState, [type]: data });
  setGlobalState(type, data);
};

export { useGlobalState, setGlobalState, getGlobalState, setStorageState };
