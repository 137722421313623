import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import Header from "../components/header";
import { Heading, Box, Flex, Button, Icon, Input } from "@chakra-ui/react";
import { setRemoteCall } from "../remote";
import moment from "moment";
import { setGlobalState, getGlobalState, useGlobalState } from "../store";
import { RowDashPage, PlayerValue, CommentNumber } from "./styled";
import { Board } from "../components/styled";
import Edit from "./Edit";
import { GoTasklist } from "react-icons/go";

/**
 *   
 * name: String,
  status: Number,
  details: String,
  color: String,
  date: Date,
  history: Object,
  user: String,
 */

/**
 * Table collumns
 */
export const tableCollumns = [
  { k: "_id", l: "_id" },
  { k: "file", l: "file", t: "upload" },
  { k: "name", l: "Title" },
  { k: "details", l: "Details", t: "textarea" },
  { k: "date", l: "date", t: "date" },
  { k: "status", l: "status", t: "status" },
  { k: "history", l: "history", t: "history" },
  { k: "type", l: "type", t: "type" },
  { k: "points", l: "points", t: "number" },
  { k: "parent", l: "parent", t: "parent" },
  { k: "user", l: "user", t: "user" }
];

function Requests(props) {
  let { id } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [toggleActive, setToggleActive] = useState(false);

  // const [data, setData] = useState(false)
  let startDate = moment()
    .subtract(1, "months")
    .format("YYYY-MM-DD");

  let endDate = moment()
    .add(1, "days")
    .format("YYYY-MM-DD");
  const [dateStart, setDateStart] = useState(startDate);
  const [dateEnd, setDateEnd] = useState(endDate);

  const [data, setData] = useGlobalState("requests"); //dont remove
  const users = getGlobalState("users");
  setGlobalState("pageClass", "requests");

  const list = {
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    },
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren"
      }
    }
  };

  const item = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 50 }
  };

  /**
   * Set table data
   */
  const set = (data, notice) => {
    setRemoteCall("setRequest", data, notice, cb => {
      getRequests(cb);
    });
  };
  /**
   * Del table data
   */
  const del = (data, notice) => {
    setRemoteCall("delRequest", data, notice, cb => {
      getRequests(cb);
    });
  };

  /**
   * get table data
   */
  const getRequests = () => {
    setRemoteCall(
      "getRequests",
      { start: dateStart, end: dateEnd, status: toggleActive ? 0 : 1 },
      "",
      cb => setData(cb)
    );
  };

  useEffect(() => {
    getRequests();
  }, [dateStart, dateEnd, toggleActive]);

  useEffect(() => {
    if (id && data && data.length > 0 && !openModal) {
      const foundRequest = data.find(d => d._id === id);
      setOpenModal(foundRequest);
    }
  }, [id, data]);
  return (
    <div className={"users dashboard"}>
      <Header image={"/images/background.jpg"} height={30} mheight={50}>
        <Flex
          alignContent={"space-between"}
          alignItems={"center"}
          flexDirection={["column", "column", "column", "row", "row"]}
          width={"100%"}
        >
          <Box width={"85%"} mb={["1em", "1em", "1em", "0", "0"]}>
            <Heading as={"h1"} size="3xl" color={"#fff"}>
              Requests
            </Heading>
            <Heading as={"h3"} size="md" color={"#fff"}>
              User requests
            </Heading>
          </Box>
          <Flex
            alignContent={"center"}
            justifyContent={"center"}
            flexDirection={["row", "row", "row", "row", "row"]}
            flexWrap={["wrap", "wrap", "nowrap"]}
          >
            <Input
              marginRight={"1em"}
              width={["40%", "40%", "100%"]}
              mt={0}
              type="date"
              value={dateStart}
              onChange={e => setDateStart(e.target.value)}
            />
            <Input
              type="date"
              width={["40%", "40%", "100%"]}
              mt={0}
              value={dateEnd}
              onChange={e => setDateEnd(e.target.value)}
            />
            <Button
              colorScheme="secondary"
              value="Submit!"
              width={["90%", "90%", "100%"]}
              ml={[0, ".4em"]}
              variant={"outline"}
              mb={["1em", "0", "0", "0", "0"]}
              _hover={{
                background: "primary.500"
              }}
              onClick={() => setToggleActive(!toggleActive)}
            >
              {!toggleActive ? "Show Complete" : "Show Active"}
            </Button>
            <Button
              colorScheme="secondary"
              value="Submit!"
              width={["90%", "90%", "100%"]}
              ml={[0, ".4em"]}
              mb={["1em", "0", "0", "0", "0"]}
              _hover={{
                background: "primary.500"
              }}
              onClick={() => setOpenModal(true)}
            >
              <Icon
                as={GoTasklist}
                color={"white"}
                height={"1em"}
                width={"1em"}
                mr={".5em"}
              />
              New Request
            </Button>
          </Flex>
        </Flex>
      </Header>
      <Box className={"wrapper"}>
        <RowDashPage
          as={motion.div}
          initial="hidden"
          animate="visible"
          variants={list}
        >
          {data &&
            data.map((request, i) => {
              let thisUser;
              if (users && request.owner) {
                thisUser = users.find(c => c._id === request.owner);
              }
              const commentNumber =
                request && request.history ? request.history.length : 0;
              return (
                <Board
                  key={i}
                  as={motion.div}
                  margin={"0 3% 3% 0"}
                  variants={item}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setOpenModal(request)}
                  // active={i === categoryId}
                >
                  {request && (request.image || request.file) && (
                    <Box
                      height={"15vh"}
                      margin={"-1em -1em 1em -1em"}
                      background={`url(${request.image || request.file})`}
                      backgroundPosition={"center"}
                      backgroundSize={"cover"}
                      border={".3em solid white"}
                      borderRadius={"1em 1em 0 0"}
                    />
                  )}
                  <Flex
                    justifyContent={"space-between"}
                    width={"100%"}
                    alignItems={"center"}
                  >
                    <Flex
                      alignContent={"center"}
                      className={"content"}
                      alignItems={"center"}
                    >
                      <Box marginRight={"1em"} width={"40px"}>
                        {request.image ? (
                          <Box
                            borderRadius={"50%"}
                            height={"4em"}
                            width={"4em"}
                            background={`url(${request.image})`}
                            backgroundPosition={"center"}
                            backgroundSize={"cover"}
                          />
                        ) : (
                          <CommentNumber>{commentNumber || 1}</CommentNumber>
                          // <Icon
                          //   as={GoTasklist}
                          //   color={'white'}
                          //   height={'2em'}
                          //   width={'2em'}
                          // />
                        )}
                      </Box>
                      <Box>
                        <PlayerValue>
                          {request.name}{" "}
                          <span>
                            {request.points ? `${request.points} pts` : ""}
                            {" / "}
                            {moment(request.date).format("DD/MM/YYYY HH:mm")}
                          </span>
                          <span>
                            {thisUser
                              ? `${thisUser.firstName} ${thisUser.lastName}`
                              : ""}{" "}
                          </span>
                        </PlayerValue>
                      </Box>
                    </Flex>
                    {thisUser && thisUser.image && (
                      <Box
                        borderRadius={"50%"}
                        height={"4.5em"}
                        width={"4.5em"}
                        background={`url(${thisUser.image})`}
                        backgroundPosition={"center"}
                        backgroundSize={"cover"}
                        border={".3em solid white"}
                      />
                    )}
                  </Flex>
                </Board>
              );
            })}
        </RowDashPage>
      </Box>

      {openModal && (
        <Edit
          data={openModal}
          form={tableCollumns}
          set={set}
          del={del}
          setOpenModal={setOpenModal}
        />
      )}
    </div>
  );
}
export default withRouter(Requests);
