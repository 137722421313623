import React, { useEffect, useState, useCallback } from "react";
import { withRouter, useParams } from "react-router-dom";
import moment from "moment";
import Header from "../components/header";
import Edit from "./Edit";
import { Flex, Box, Heading, Input, Button } from "@chakra-ui/react";
import { getGlobalState, setGlobalState } from "../store";
import { MdRecycling } from "react-icons/md";
import { setRemoteCall } from "../remote";
import TableProvider from "../components/Table/Table";
import { calcCo2, calcPower } from "../util";
import { Board } from "../components/styled";
import { MetricValue } from "../Requests/styled";
import { GiPineTree } from "react-icons/gi";
import { ImPowerCord } from "react-icons/im";
import { RiGamepadLine } from "react-icons/ri";
import { SiGumtree } from "react-icons/si";
import { FaSmog } from "react-icons/fa";
import EditOffsets from "./EditOffsets";
/**
 * Table collumns
 */
export const tableCollumns = [
  { k: "_id", l: "_id" },
  { k: "task", l: "Task" },
  { k: "status", l: "Status" },
  { k: "devices", l: "Devices", t: "devices" },
  { k: "start", l: "Start Date", t: "date" },
  { k: "end", l: "End Date", t: "date" },
  { k: "duration", l: "Duration (H)", t: "duration" },
  { k: "planned", l: "Planned (H)", t: "planned" },
  { k: "power", l: "Power (Kwh)", t: "power" },
  { k: "co2", l: "Co2 (Kg)", t: "co2" },
  { k: "sameas", l: "Car (Km)", t: "sameas" },
  { k: "offset", l: "Offset (Trees)", t: "offset" },
  { k: "user", l: "User" },
  { k: "details", l: "Description" },
  { k: "group", l: "Group" }
];

function Reports(props) {
  let { dateStartURL, dateEndURL } = useParams();
  let startDate = moment()
    .subtract(1, "months")
    .format("YYYY-MM-DD");
  if (dateStartURL) {
    startDate = moment(dateStartURL, "DD-MM-YYYY").format("YYYY-MM-DD");
  }
  let endDate = moment()
    .add(1, "days")
    .format("YYYY-MM-DD");
  if (dateEndURL) {
    endDate = moment(dateEndURL, "DD-MM-YYYY")
      .add(1, "days")
      .format("YYYY-MM-DD");
  }
  /**
   * Get Data
   */
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState(false);
  const users = getGlobalState("users");
  const offsets = getGlobalState("offsets");
  const types = getGlobalState("userTypes");
  const [dateStart, setDateStart] = useState(startDate);
  const [dateEnd, setDateEnd] = useState(endDate);
  const [openOffset, setOpenOffset] = useState(false);
  //set the initical class name
  setGlobalState("pageClass", "reports");

  const collumns = tableCollumns;

  /**
   * get table data
   */
  const getReports = useCallback(() => {
    setRemoteCall("getReports", { start: dateStart, end: dateEnd }, "", cb =>
      setData(cb)
    );
  }, [dateStart, dateEnd]);

  /**
   * Convert data before export
   */
  const preprocessData = tdata => {
    if (!tdata) return [];

    const calcDuration = data => {
      const start = moment(data.start);
      let end = moment();
      if (data.end) {
        end = moment(data.end);
      }
      const durationLogFull = moment.duration(end.diff(start));
      const secondFull = durationLogFull.asHours();

      return secondFull.toFixed(2);
    };

    const getUsers = data => {
      const thisUser = users.find(u => data.user && data.user.includes(u._id));
      return thisUser ? `${thisUser.firstName} ${thisUser.lastName}` : "Team";
    };

    const getGroup = data => {
      if (data.group && data.group.length >= 1) {
        return data.group
          .map(user => {
            const thisUser = users.find(u => user === u._id);
            return thisUser ? `${thisUser.firstName} ${thisUser.lastName}` : "";
          })
          .join(", ");
      } else {
        return "";
      }
    };

    const getRole = data => {
      const thisUser = users.find(u => data.user && data.user.includes(u._id));
      const thisType = thisUser && types.find(t => t._id === thisUser.role);
      return thisType ? thisType.name : "";
    };

    const getPower = (data, country) => {
      return calcPower(data.start, data.end, data.devices, country);
    };

    return (
      tdata?.map(data => {
        const thisUser = users.find(
          u => data.user && data.user.includes(u._id)
        );

        return {
          ...data,
          status: !Number(data.status) ? "Finished" : "Ongoing",
          start: moment(data.start).format("DD-MM-YYYY HH:mm:ss"),
          end: moment(data.end).format("DD-MM-YYYY HH:mm:ss"),
          duration: calcDuration(data),
          planned:
            data && data.attr && data.attr.planned
              ? (data.attr.planned / 60).toFixed(2)
              : 0,
          power: getPower(data, thisUser?.country).power,
          co2: calcCo2(getPower(data, thisUser?.country), thisUser?.country),
          sameas: (
            calcCo2(getPower(data, thisUser?.country), thisUser?.country) * 3.09
          ).toFixed(2),
          offset: (
            calcCo2(getPower(data, thisUser?.country), thisUser?.country) *
            0.00714
          ).toFixed(4),
          devices: (data && data?.devices?.map(d => d.name).join(" | ")) || "",
          user: getUsers(data),
          group: getGroup(data),
          role: getRole(data)
        };
      }) || []
    );
  };

  /**
   *  1 Ton CO2 = 7,14 Arvores
   *  Carro diesel 1km = 3.09 Co2 kg
   *  Eletricidade 1KWH = 0.31552 Co2 g
   */

  useEffect(() => {
    getReports();
  }, [dateStart, dateEnd]);

  return (
    <div className={"reports table-wrapper"}>
      <Header image={"/images/background.jpg"} height={30} mheight={40}>
        <Flex
          width={"100%"}
          alignItems={"center"}
          justifyContent={"space-between"}
          flexDirection={["column", "column", "row", "row", "row"]}
        >
          <div>
            <Heading as="h1" size="3xl" color={"#fff"}>
              CO2 Log
            </Heading>
            <Heading as="h3" size="md" color={"#fff"}></Heading>
          </div>
          <Flex
            width={["100%", "100%", "60%", "60%", "60%"]}
            flexDirection={["column", "column", "row", "row", "row"]}
            alignContent={"center"}
            justifyContent={"center"}
          >
            <Button
              colorScheme="secondary"
              value="Submit!"
              width={"100%"}
              variant={"outline"}
              mb={["1em", "0", "0", "0", "0"]}
              _hover={{
                background: "primary.500"
              }}
              onClick={() =>
                setOpenModal({
                  task: "Competition",
                  type: "competition",
                  user: "team",
                  status: 0,
                  start: moment(),
                  end: moment().add("hours", 1)
                })
              }
            >
              Add a Team Log
            </Button>
            <Box>
              <Button
                colorScheme="secondary"
                value="Submit!"
                width={"100%"}
                mr={[0, ".4em"]}
                mb={["1em", "0", "0", "0", "0"]}
                _hover={{
                  background: "primary.500"
                }}
                onClick={() => setOpenOffset(true)}
              >
                <SiGumtree
                  fill={"white"}
                  height={"1em"}
                  width={"1em"}
                  mr={".5em"}
                  style={{ marginRight: ".5em" }}
                />
                Offset
              </Button>
            </Box>

            <Input
              marginRight={"1em"}
              mt={0}
              type="date"
              value={dateStart}
              onChange={e => setDateStart(e.target.value)}
            />
            <Input
              mt={0}
              type="date"
              value={dateEnd}
              onChange={e => setDateEnd(e.target.value)}
            />
          </Flex>
        </Flex>
      </Header>
      <Flex
        width={"100%"}
        m={"0 auto"}
        flexWrap={{ base: "wrap", md: "nowrap" }}
      >
        <Board margin={"0 1em"}>
          {" "}
          <Flex alignItems={"center"}>
            <ImPowerCord size={45} />
            <Box ml={".5em"} fontSize={"1.4em"}>
              <MetricValue>
                {data
                  ? data
                      .reduce((acc, curr) => {
                        if (curr.start && curr.end && curr.devices) {
                          const thisUser = users.find(
                            u => data.user && data.user.includes(u._id)
                          );
                          const thisPower = calcPower(
                            curr.start,
                            curr.end,
                            curr.devices,
                            thisUser?.country
                          );
                          return acc + Number(thisPower.power || 0);
                        }

                        return acc;
                      }, 0)
                      .toFixed(1)
                  : 0}
                {/* {yearTimeLog && yearTimeLog?.total?.power.toFixed(1)} */}
                <span>Kw/h</span>
              </MetricValue>
            </Box>
          </Flex>
        </Board>
        <Board margin={"0 1em"}>
          <Flex alignItems={"center"}>
            <RiGamepadLine size={45} />
            <Box ml={".5em"} fontSize={"1.4em"}>
              <MetricValue>
                {data
                  ? data
                      .reduce((acc, curr) => {
                        if (curr.start && curr.end) {
                          const rowData = curr;

                          const start = moment(rowData.start);
                          let end = moment();
                          if (rowData.end) {
                            end = moment(rowData.end);
                          }
                          const durationLogFull = moment.duration(
                            end.diff(start)
                          );
                          const secondFull = durationLogFull.asHours();
                          return acc + Number(secondFull);
                        }
                        return acc;
                      }, 0)
                      .toFixed(1)
                  : 0}
                <span>Hours</span>
              </MetricValue>
            </Box>
          </Flex>
        </Board>
        <Board margin={"0 1em"}>
          <Flex alignItems={"center"}>
            <SiGumtree size={45} />
            <Box ml={".5em"} fontSize={"1.4em"}>
              <MetricValue>
                {data
                  ? data
                      .reduce((acc, curr) => {
                        if (curr.start && curr.end && curr.devices) {
                          const thisUser = users.find(
                            u => curr.user && curr.user.includes(u._id)
                          );

                          // 1 Ton CO2 = 7,14 Arvores
                          const power = calcPower(
                            curr.start,
                            curr.end,
                            curr.devices,
                            thisUser?.country
                          );

                          const co2 = calcCo2(power, thisUser?.country);

                          const tress = (co2 / 1000) * 7.14;
                          return acc + Number(tress);
                        }
                        return acc;
                      }, 0)
                      .toFixed(1)
                  : 0}
                <span>Trees</span>
              </MetricValue>
            </Box>
          </Flex>
        </Board>
        <Board active={true} margin={"0 1em"}>
          <Flex alignItems={"center"}>
            <FaSmog size={45} />
            <Box ml={".5em"} fontSize={"1.4em"}>
              <MetricValue>
                {data
                  ? data
                      .reduce((acc, curr) => {
                        if (curr.start && curr.end && curr.devices) {
                          const thisUser = users.find(
                            u => curr.user && curr.user.includes(u._id)
                          );
                          // 1 Ton CO2 = 7,14 Arvores
                          const power = calcPower(
                            curr.start,
                            curr.end,
                            curr.devices,
                            thisUser?.country
                          );

                          const co2 = calcCo2(power, thisUser?.country);

                          return acc + Number(co2);
                        }
                        return acc;
                      }, 0)
                      .toFixed(1)
                  : 0}
                <span>Kg CO2</span>
              </MetricValue>
            </Box>
          </Flex>
        </Board>
        <Board margin={"0 1em"}>
          <Flex alignItems={"center"}>
            <MdRecycling size={45} color="green" />
            <Box ml={".5em"} fontSize={"1.4em"}>
              <MetricValue>
                {offsets
                  ? offsets
                      .reduce((acc, curr) => {
                        if (curr?.value) {
                          return acc + Number(curr.value || 0) / 1000;
                        }
                        return acc;
                      }, 0)
                      .toFixed(1)
                  : 0}
                <span>Ton CO2</span>
              </MetricValue>
            </Box>
          </Flex>
        </Board>
      </Flex>
      <Box className={"content"} padding={"0 1em"}>
        {data && (
          <div className={"contentTableMap"}>
            <div className={"tableWrapper"}>
              <TableProvider
                data={preprocessData(data)}
                columns={collumns.map(key => {
                  if (key.k === "status") {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true
                      }
                    };
                  }
                  if (key.t === "date") {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true
                      }
                    };
                  }
                  if (key.t === "date") {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true
                      }
                    };
                  }
                  if (key.t === "duration") {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true
                      }
                    };
                  }
                  if (key.t === "planned") {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true
                      }
                    };
                  }
                  if (key.t === "power") {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true
                      }
                    };
                  }
                  if (key.t === "co2") {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true
                      }
                    };
                  }
                  if (key.t === "sameas") {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true
                      }
                    };
                  }
                  if (key.t === "offset") {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true
                      }
                    };
                  }
                  if (key.t === "devices") {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true
                      }
                    };
                  }
                  if (key.k === "user") {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true
                      }
                    };
                  }
                  if (key.k === "group") {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true
                      }
                    };
                  }
                  if (key.k === "role") {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true
                      }
                    };
                  }
                  return {
                    name: key.k,
                    label: key.l,
                    selector: key.k,
                    options: {
                      filter: true,
                      sort: true,
                      display:
                        key.k !== "_id" &&
                        key.k !== "availability" &&
                        key.k !== "location" &&
                        key.k !== "__v"
                    }
                  };
                })}
                height={55}
                options={{
                  rowsPerPageOptions: [10, 100, 200, 500, 10000],
                  selectableRows: "none",
                  fixedSelectColumn: false,
                  filterType: "dropdown",
                  responsive: "stacked",
                  downloadOptions: {
                    filename: "report.csv",
                    separator: ";",
                    filterOptions: {
                      useDisplayedColumnsOnly: true,
                      useDisplayedRowsOnly: true
                    }
                  },
                  onDownload: (buildHead, buildBody, columns, data) => {
                    return "\uFEFF" + buildHead(columns) + buildBody(data);
                  },
                  onRowClick: (row, b) => {
                    setOpenModal(data.find(rowD => rowD._id === row[0]));
                  }
                }}
              />
            </div>
          </div>
        )}
        {openOffset && <EditOffsets setOpenModal={setOpenOffset} />}
        {openModal && (
          <Edit
            data={openModal}
            form={collumns}
            types={types}
            getReports={getReports}
            setOpenModal={setOpenModal}
          />
        )}
      </Box>
    </div>
  );
}

export default withRouter(Reports);
