import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./index.css";
import { configAxios } from "./util";
import { initNotification } from "./notifications";
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-modal/styles.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import App from "./app/App";

Sentry.init({
  dsn: "https://0a8d64f01670414b9642a668ed5a28e9@o114000.ingest.sentry.io/5900775",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

configAxios();
initNotification();

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: registration => {
    registration.unregister().then(() => {
      window.location.reload();
    });
  },
  onSuccess: registration => {
    console.info("service worker on success state");
    console.log(registration);
  }
});
