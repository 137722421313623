//axios setup
import axios from "axios";
import moment from "moment";
import co2 from "./co2";

export const presistData = (type, data) => {
  try {
    if (typeof localStorage !== "undefined")
      localStorage[type] = JSON.stringify({ ...data, status: "" });
  } catch (error) {
    console.log(error);
  }
  return data;
};

export function jsonEquals(a, b) {
  return JSON.stringify(a) === JSON.stringify(b);
}

export const getPresistData = (type, data) => {
  try {
    if (typeof localStorage !== "undefined" && localStorage[type]) {
      return JSON.parse(localStorage[type])[data];
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const configAxios = () => {
  axios.defaults.baseURL = `${
    process.env.API_URL || "https://betclicapi.cors.digital"
  }/api/`;

  axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded";
  axios.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";
  axios.defaults.withCredentials = false; // for session keeping
  try {
    if (
      typeof localStorage === "undefined" &&
      !localStorage.getItem("betclicApp")
    ) {
      throw new Error("no user data");
    }

    const userData = JSON.parse(localStorage.getItem("betclicApp") || null);
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + userData.loggedIn.token;
  } catch (error) {
    axios.defaults.headers.common["Authorization"] = "";
  }
};

export const calcPower = (startTime, endTime, devices, country) => {
  const start = moment(startTime);
  let end = moment();
  if (endTime) {
    end = moment(endTime);
  }
  const durationLogFull = moment.duration(end.diff(start));
  const secondFull = durationLogFull.asHours();

  const thisCountryData = co2.data.find(
    (c) => country?.toLowerCase() === c.name.toLowerCase()
  );

  let fullPower = 0;
  let thisCo2 = 0;
  devices &&
    devices.map((d) => {
      switch (d.type) {
        case "train":
          thisCo2 += Number(d.usage) * 0.049 * Number(d.people || 1);
          break;
        case "bus":
          thisCo2 += Number(d.usage) * 2.28 * Number(d.people || 1);
          break;
        case "train":
          thisCo2 += Number(d.usage) * 0.049 * Number(d.people || 1);
          break;
        case "car":
          thisCo2 += Number(d.usage) * 3.09 * Number(d.people || 1);
          break;
        case "airplane":
          thisCo2 += Number(d.usage) * 0.11 * Number(d.people || 1);
          break;
        case "playing":
          //lets say its 1000 kwh
          // thisCo2 += Number(d.usage) * Number(d.power || 0.1) * 0.31552;

          thisCo2 +=
            Number(d.usage) *
            Number(d.power || 0.1) *
            (thisCountryData?.value / 1000 || 0.31552);

          break;
        default:
          fullPower += Number(d.power) * secondFull;
          break;
      }
      return d;
    });
  return { power: fullPower, co2: thisCo2 };
  // } else {
  //   let fullPower = 0
  //   rowData[3] &&
  //     rowData[3].map(d => (fullPower += Number(d.power) * secondFull))
  //   return fullPower
  // }
};
export const calcCo2 = (usage, country) => {
  //  Eletricidade 1KWH = 0.31552 Co2 kg
  const thisCountryData = co2.data.find(
    (c) => country?.toLowerCase() === c.name.toLowerCase()
  );

  if (usage.co2) {
    return usage.co2;
  }

  // return (usage.power * 0.31552);
  //convert to kg /1000
  return usage.power * (thisCountryData?.value / 1000 || 0.31552);
};
