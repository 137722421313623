import React, { useState, useEffect, useCallback } from "react";
import { motion } from "framer-motion";
import Header from "../components/header";
import { Heading, Box, Flex, Input, Button, Icon } from "@chakra-ui/react";
import { AiOutlineClockCircle, AiFillClockCircle } from "react-icons/ai";
import { setRemoteCall } from "../remote";
import { colors } from "../theme";
import Edit from "../Requests/Edit";
import moment from "moment";
import { setGlobalState, useGlobalState, getGlobalState } from "../store";
import { RowDashPage, PlayerValue, DayValue } from "./styled";
import { Board } from "../components/styled";
import { Calendar as CalendarIcon } from "../components/navigation/icons";

/**
 *   
 * name: String,
  status: Number,
  details: String,
  color: String,
  date: Date,
  history: Object,
  user: String,
 */

/**
 * Table collumns
 */
export const tableCollumns = [
  { k: "_id", l: "_id" },
  { k: "image", l: "image", t: "upload" },
  { k: "name", l: "Title" },
  { k: "details", l: "Details", t: "textarea" },
  { k: "date", l: "date", t: "date" }
];

function Calendar(props) {
  // const [data, setData] = useState(false)
  const loggedIn = getGlobalState("loggedIn");
  let startDate = moment()
    .startOf("week")
    .format("YYYY-MM-DD");
  const [openModal, setOpenModal] = useState(false);

  let endDate = moment()
    // .endOf("week")
    .add(1, "day")
    .format("YYYY-MM-DD");
  const [dateStart, setDateStart] = useState(startDate);
  const [dateEnd, setDateEnd] = useState(endDate);

  const [data, setData] = useGlobalState("calendar"); //dont remove
  // const users = getGlobalState('users')
  setGlobalState("pageClass", "feed");

  const list = {
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    },
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren"
      }
    }
  };

  const item = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 50 }
  };

  /**
   * get table data
   */
  const getRequests = useCallback(() => {
    setRemoteCall(
      "getCalendar",
      {
        start: dateStart,
        end: dateEnd,
        country: loggedIn.country,
        admin: loggedIn.admin
      },
      "",
      cb => setData(cb)
    );
  }, [dateStart, dateEnd, setData]);

  useEffect(() => {
    getRequests();
  }, [dateStart, dateEnd]);

  // useEffect(() => {
  //   if (id && data && data.length > 0 && !openModal) {
  //     const foundRequest = data.find(d => d._id === id)
  //     setOpenModal(foundRequest)
  //   }
  // }, [id, data])
  let currentMonth = "";

  /**
   * Set table data
   */
  const set = (data, notice) => {
    setRemoteCall("setRequest", data, notice, cb => {
      getRequests(cb);
    });
  };
  /**
   * Del table data
   */
  const del = (data, notice) => {
    setRemoteCall("delRequest", data, notice, cb => {
      getRequests(cb);
    });
  };
  // /**
  //  * Set table data
  //  */
  // const set = (data, notice) => {
  //   setRemoteCall("setNews", data, notice, cb => {
  //     getRequests(cb);
  //   });
  // };
  // /**
  //  * Del table data
  //  */
  // const del = (data, notice) => {
  //   setRemoteCall("delNews", data, notice, cb => {
  //     getRequests(cb);
  //   });
  // };
  return (
    <div className={"users dashboard"}>
      <Header image={"/images/background.jpg"} height={30} mheight={30}>
        <Flex
          alignContent={"space-between"}
          alignItems={"center"}
          flexDirection={["column", "column", "column", "row", "row"]}
          width={"100%"}
        >
          <Box width={"85%"} mb={["1em", "1em", "1em", "0", "0"]}>
            <Heading as={"h1"} size="3xl" color={"#fff"}>
              News Feed
            </Heading>
            <Heading as={"h3"} size="md" color={"#fff"}>
              Team Calendar view
            </Heading>
          </Box>
          <Flex
            alignContent={"center"}
            justifyContent={"center"}
            width={"100%"}
            flexDirection={["row", "row", "row", "row", "row"]}
          >
            <Input
              marginRight={"1em"}
              mt={0}
              width={["35%", "100%"]}
              type="date"
              value={dateStart}
              onChange={e => setDateStart(e.target.value)}
            />
            <Input
              type="date"
              width={["35%", "100%"]}
              mt={0}
              value={dateEnd}
              onChange={e => setDateEnd(e.target.value)}
            />
            {/* {loggedIn && loggedIn.admin ? (
              <Button
                colorScheme="secondary"
                value="Submit!"
                width={"100%"}
                ml={[0, ".4em"]}
                mb={["1em", "0", "0", "0", "0"]}
                _hover={{
                  background: "primary.500"
                }}
                onClick={() => setOpenModal(true)}
              >
                <CalendarIcon
                  fill={"white"}
                  height={"1em"}
                  width={"1em"}
                  mr={".5em"}
                  style={{ marginRight: ".5em" }}
                />
                Add News
              </Button>
            ) : (
              ""
            )} */}
          </Flex>
        </Flex>
      </Header>
      <Box className={"wrapper"}>
        <RowDashPage
          as={motion.div}
          initial="hidden"
          animate="visible"
          variants={list}
        >
          {data &&
            data.map((request, i) => {
              // let thisUser
              // if (users) {
              //   thisUser = users.find(c => c._id === request.user)
              // }
              let start = null;
              let end = null;
              if (request.created_at) {
                start = moment(request.created_at);
              } else if (request.start && request.start.date) {
                start = moment(request.start.date);
                end = moment(request.end.date);
              } else if (request.date) {
                start = moment(request.date);
              } else {
                start = moment(request.start.dateTime);
                end = moment(request.end.dateTime);
              }
              let link = null;
              if (request.type === "google") {
                link = request.htmlLink;
              } else if (request.type === "tweet") {
                link = `https://twitter.com/BetclicApogee/status/${request.id}`;
              }

              const thisMonth = start.format("MMMM");
              let displayMonth = false;
              if (currentMonth !== thisMonth) {
                currentMonth = thisMonth;
                displayMonth = true;
              }
              return (
                <>
                  {displayMonth && (
                    <Box
                      alignSelf={"stretch"}
                      width={"100%"}
                      margin={"2em 0"}
                      textAlign={"center"}
                    >
                      <Box
                        height="5em"
                        display={"inline-block"}
                        width={"1px"}
                        borderRight={`1px dashed ${colors.secondary[500]}`}
                        // background={colors.secondary[500]}
                      ></Box>
                      <Box
                        color={colors.secondary[500]}
                        fontSize={"2em"}
                        lineHeight={"0"}
                        mb={".5em"}
                      >
                        ●
                      </Box>
                      <Heading>{thisMonth}</Heading>
                    </Box>
                  )}
                  <Board
                    key={i}
                    margin={"0 3% 3% 0"}
                    as={motion.div}
                    variants={item}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    // active={i === categoryId}
                  >
                    <a
                      href={link}
                      target={"_blank"}
                      onClick={e => {
                        if (
                          request.type !== "google" &&
                          request.type !== "tweet" &&
                          loggedIn &&
                          loggedIn.admin
                        ) {
                          e.preventDefault();
                          setOpenModal(request);
                        }
                      }}
                    >
                      {request && (request.image || request.file) && (
                        <Box
                          height={"15vh"}
                          margin={"-1em -1em 1em -1em"}
                          background={`url(${request.image || request.file})`}
                          backgroundPosition={"center"}
                          backgroundSize={"cover"}
                          border={".3em solid white"}
                          borderRadius={"1em 1em 0 0"}
                        />
                      )}
                      <Flex
                        alignContent={"center"}
                        className={"content"}
                        alignItems={"center"}
                      >
                        <Box marginRight={"1em"} width={"60px"}>
                          <DayValue>
                            {/* {daysPassed} */}
                            {start.date()}
                            <span>
                              {moment(start.month() + 1, "M").format("MMM")}{" "}
                            </span>
                          </DayValue>
                        </Box>
                        <Box>
                          <PlayerValue>
                            {request.summary || request.name}
                            <span>{request.details || request.text}</span>
                            <Flex mt={".5em"} alignItems={"center"}>
                              <Box mr={".3em"}>
                                <AiFillClockCircle size={15} />
                              </Box>
                              <span style={{ fontSize: "1.3em" }}>
                                {moment(start).format("HH:mm")}
                              </span>
                            </Flex>
                            {end && (
                              <Flex>
                                <Box mr={".3em"}>
                                  <AiOutlineClockCircle size={15} />
                                </Box>
                                <span>{moment(end).format("HH:mm")}</span>
                              </Flex>
                            )}
                          </PlayerValue>
                        </Box>
                      </Flex>
                    </a>
                  </Board>
                </>
              );
            })}
        </RowDashPage>
      </Box>

      {openModal && (
        <Edit
          data={openModal}
          form={tableCollumns}
          set={set}
          del={del}
          setOpenModal={setOpenModal}
        />
      )}
    </div>
  );
}
export default Calendar;
