import React, { useState, useEffect } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useGlobalState, getGlobalState } from "../store";

import { userLoggedIn, checkAuthRoute, checkAdminRoute } from "../remote";

import "./App.css";
import { over, getNewOver } from "../theme";
import Page from "../components/page";
import Login from "../login/Login";
import Register from "../login/Register";
import Reset from "../login/Reset";
import Users from "../users/Users";
import Dashboard from "../dashboard/Dashboard";
import DashboardAdmin from "../dashboard/DashboardAdmin";
import Calendar from "../Calendar/Calendar";
import Trello from "../Trello/Trello";
import Ranks from "../Ranks/Ranks";
import News from "../News/News";
import Requests from "../Requests/Requests";
import Reports from "../Reports/Reports";
import WatchAuth from "../auth/WatchAuth";
import EditProfile from "../users/EditProfile";
import PWAPrompt from "react-ios-pwa-prompt";
import ConnectionModal from "../components/Modal/ConnectionModal";

export default function App() {
  //we need this to rerender the app upon login
  const [loggedIn, setLoggedIn] = useGlobalState("loggedIn"); //dont remove  // const loggedIn = getGlobalState('loggedIn')
  const [darkMode, setDarkMode] = useGlobalState("darkMode"); //dont remove  // updating dakmode
  const [overProcessed, setOver] = useState(over);
  userLoggedIn();
  // useEffect(() => setOver(getNewOver(darkMode)), [darkMode])
  return (
    <ChakraProvider theme={getNewOver(darkMode) || over}>
      <Router>
        <WatchAuth>
          <Page>
            <Switch>
              <Route
                exact
                path="/dashboard"
                component={() => {
                  if (loggedIn && loggedIn.role && loggedIn.role === "global") {
                    return checkAdminRoute() && <DashboardAdmin />;
                  } else {
                    return checkAuthRoute() && <Dashboard />;
                  }
                }}
              />
              <Route
                exact
                path="/reset/:token"
                component={() => (
                  <Reset
                  // setStorageState={this.setStorageState}
                  />
                )}
              />

              <Route
                exact
                path="/users"
                component={() => checkAuthRoute() && <Users />}
              />
              <Route
                exact
                path="/feed"
                component={() => checkAuthRoute() && <Calendar />}
              />
              <Route
                exact
                path="/ranks"
                component={() => checkAdminRoute() && <Ranks />}
              />
              <Route
                exact
                path="/news"
                component={() => checkAdminRoute() && <News />}
              />
              <Route
                exact
                path="/trello"
                component={() => checkAuthRoute() && <Trello />}
              />

              <Route
                exact
                path="/requests/:id?"
                component={() => checkAuthRoute() && <Requests />}
              />
              <Route
                exact
                path="/reports"
                component={() => checkAdminRoute() && <Reports />}
              />

              <Route
                exact
                path="/"
                component={() => checkAuthRoute(true) && <Login />}
              />
              <Route exact path="/register" component={() => <Register />} />
            </Switch>
          </Page>
        </WatchAuth>
      </Router>
      <ToastContainer
        position="top-right"
        hideProgressBar={true}
        closeOnClick
      />
      <EditProfile />
      <ConnectionModal />

      <PWAPrompt
        promptOnVisit={1}
        timesToShow={3}
        copyClosePrompt="Close"
        permanentlyHideOnDismiss={false}
      />
    </ChakraProvider>
  );
}
