import React from "react";
import {
  Input,
  Button,
  Box,
  Flex,
  ButtonGroup,
  Switch,
  useToast,
  FormControl,
  FormLabel
} from "@chakra-ui/react";
import { Board } from "../../components/styled";
import Tv from "../icons/Tv";
import Mouse from "../icons/Mouse";
import Joypad from "../icons/Joypad";
const RenderEditDevice = ({
  i,
  removeDevice,
  handleFormFieldChange,
  addDevice,
  details
}) => {
  const toast = useToast();
  return (
    <Board width={"100%"}>
      <div>
        <Flex alignItems={"center"}>
          <Input
            width={"65%"}
            padding={".5em .5em"}
            height={"3em"}
            onChange={event => handleFormFieldChange("name", event)}
            type={"text"}
            placeholder="Name"
            value={details.name}
          />

          <ButtonGroup width={"50%"} marginLeft={"4%"} size="md" isAttached>
            <Button
              colorScheme={
                details.type === "computer" ? "secondary" : "primary"
              }
              variant={details.type === "computer" ? "solid" : "outline"}
              onClick={() =>
                handleFormFieldChange("type", {
                  target: { value: "computer" }
                })
              }
            >
              <Mouse fill={"#fff"} height={25} width={25} />
            </Button>
            <Button
              colorScheme={details.type === "console" ? "secondary" : "primary"}
              variant={details.type === "console" ? "solid" : "outline"}
              onClick={() =>
                handleFormFieldChange("type", {
                  target: { value: "console" }
                })
              }
            >
              <Joypad fill={"#fff"} height={25} width={25} />
            </Button>
            <Button
              colorScheme={details.type === "tv" ? "secondary" : "primary"}
              variant={details.type === "tv" ? "solid" : "outline"}
              onClick={() =>
                handleFormFieldChange("type", { target: { value: "tv" } })
              }
            >
              <Tv fill={"#fff"} height={25} width={25} />
            </Button>
            {/* <IconButton aria-label="Add to friends" icon={<AddIcon />} /> */}
          </ButtonGroup>

          {/* <Select
onChange={event => handleFormFieldChange('type', event)}
placeholder="Select option"
>
<option value={'PC'}> PC </option>
<option value={'Console'}> Console </option>
</Select> */}
        </Flex>
        <Input
          padding={".5em .5em"}
          height={"3em"}
          onChange={event => {
            if (event.target.value > 2) {
              return toast({
                title: "Please check that you are using Kw/h",
                status: "warning",
                position: "bottom"
              });
            }
            handleFormFieldChange("power", event);
          }}
          type={"number"}
          placeholder="Power Consumption (Kw/h)"
          value={details.power}
        />
        <FormControl
          display="flex"
          justifyContent={"center"}
          alignItems="center"
        >
          <FormLabel htmlFor="default-device" mb="0">
            Is this a default device?
          </FormLabel>
          <Switch
            id="default-device"
            isChecked={details.isDefault || false}
            onChange={event => {
              handleFormFieldChange("isDefault", {
                target: { value: event.target.checked }
              });
            }}
          />
        </FormControl>
        <Flex marginBottom={"2em"}>
          {removeDevice && (
            <Button
              margin={"1em 0"}
              size={"sm"}
              width={"100%"}
              height={"3em"}
              variant={"outline"}
              onClick={() => removeDevice(i)}
            >
              - Remove device
            </Button>
          )}
          <Button
            margin={"1em 0 0 1em"}
            size={"sm"}
            width={"100%"}
            height={"3em"}
            onClick={() => addDevice()}
          >
            + Save device
          </Button>
        </Flex>
      </div>
    </Board>
  );
};

export default RenderEditDevice;
