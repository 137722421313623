import { Box, Flex, Heading, Progress, Tooltip } from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaSmog } from "react-icons/fa";
import { ImPowerCord } from "react-icons/im";
import { RiGamepadLine } from "react-icons/ri";
import { MdRecycling } from "react-icons/md";
import { SiGumtree } from "react-icons/si";
import { Link } from "react-router-dom";
import co2 from "../co2";
import Header from "../components/header";
import Status from "../components/Status/Status";
import { Board } from "../components/styled";
import Timer from "../components/Timer";
import { setRemoteCall } from "../remote";
import {
  CommentNumber,
  MetricValue,
  MetricValueWhite,
  PlayerValue,
} from "../Requests/styled";
import { getGlobalState, setGlobalState, useGlobalState } from "../store";
import { colors } from "../theme";
import Joypad from "../users/icons/Joypad";
import Mouse from "../users/icons/Mouse";
import Tv from "../users/icons/Tv";
import { DeviceName, RotatedCard, RowDashPage, Wrap } from "./styled";
// const MAINCOLORS = [colors.primary[500], colors.primary[200]]
const SUBCOLORS = [
  colors.secondary[500],
  colors.secondary[400],
  colors.secondary[300],
  colors.secondary[200],
  colors.secondary[100],
];

export default function Dashboard(props) {
  setGlobalState("pageClass", "dashboard");
  const offsets = getGlobalState("offsets");
  const loggedIn = getGlobalState("loggedIn"); //dont remove
  const [requests] = useGlobalState("requests"); //dont remove
  const users = getGlobalState("users");
  // let userClient = null
  // if (loggedIn && !loggedIn.admin) {
  //   userClient = loggedIn.role
  // }
  // const types = getGlobalState('userTypes')
  const [monthTimeLog, setMonthTimeLog] = useState([]);
  const [yearTimeLog, setYearTimeLog] = useState([]);
  const [yearOffset, setYearOffset] = useState([]);
  const [barData, setBarData] = useState([]);

  const [printing] = useState(false);
  useEffect(() => {
    getMonthData();
    getYearStats();
  }, []);

  useEffect(() => {
    workBarData(yearTimeLog);
  }, [yearTimeLog]);

  const getMonthData = () => {
    setRemoteCall("getMonthTimeLog", { user: loggedIn._id }, "", (cb) => {
      setMonthTimeLog(cb);
    });
  };
  const getYearStats = () => {
    setRemoteCall("getTeamStats", {}, "", (cb) => {
      setYearTimeLog(cb);
    });
    setRemoteCall("getCurrentYearOffsets", {}, "", (cb) => {
      setYearOffset(cb);
    });
  };

  const getLevelName = () => {
    if (
      yearTimeLog &&
      yearTimeLog.totalPointsAveragePerUser &&
      yearTimeLog.totalPointsAveragePerUser > 0
    ) {
      let levelName = "Newbie";
      if (yearTimeLog.totalPointsAveragePerUser >= 150) {
        levelName = "World Class";
      } else if (yearTimeLog.totalPointsAveragePerUser >= 100) {
        levelName = "Professional";
      } else if (yearTimeLog.totalPointsAveragePerUser >= 50) {
        levelName = "Amateur";
      } else if (yearTimeLog.totalPointsAveragePerUser >= 0) {
        levelName = "Newbie";
      }
      return levelName;
    }
    return "Newbie";
  };

  const getNexLevelPoints = () => {
    if (
      yearTimeLog &&
      yearTimeLog.totalPointsAveragePerUser &&
      yearTimeLog.totalPointsAveragePerUser > 0
    ) {
      let nextLevelPoint = 50;

      if (yearTimeLog.totalPointsAveragePerUser >= 150) {
        nextLevelPoint = 200 - yearTimeLog.totalPointsAveragePerUser;
      } else if (yearTimeLog.totalPointsAveragePerUser >= 100) {
        nextLevelPoint = 150 - yearTimeLog.totalPointsAveragePerUser;
      } else if (yearTimeLog.totalPointsAveragePerUser >= 50) {
        nextLevelPoint = 100 - yearTimeLog.totalPointsAveragePerUser;
      } else if (yearTimeLog.totalPointsAveragePerUser >= 0) {
        nextLevelPoint = 50 - yearTimeLog.totalPointsAveragePerUser;
      }
      return nextLevelPoint;
    }
    return 0;
  };

  const getCurrentLevelProgress = () => {
    if (
      yearTimeLog &&
      yearTimeLog.totalPointsAveragePerUser &&
      yearTimeLog.totalPointsAveragePerUser > 0
    ) {
      let totalRankPoints = 0;
      if (yearTimeLog.totalPointsAveragePerUser >= 150) {
        totalRankPoints = 200;
      }
      if (yearTimeLog.totalPointsAveragePerUser >= 100) {
        totalRankPoints = 150;
      } else if (yearTimeLog.totalPointsAveragePerUser >= 50) {
        totalRankPoints = 100;
      }
      return (totalRankPoints * 100) / 50;
    }
    return 0;
  };

  const workBarData = (data, i) => {
    const { outputDataPerMonth, total } = data;
    if (outputDataPerMonth && Object.keys(outputDataPerMonth).length > 0) {
      let dataInit = [];
      // const parent = Object.keys(data.mainCat)[i || 0]
      Object.keys(outputDataPerMonth).map((month) => {
        const thisInit = outputDataPerMonth[month];
        if (thisInit)
          dataInit.push({
            subject: thisInit.date,
            // A: Math.floor(Math.random() * 100) + 1,
            ["Kw/h"]: Number(thisInit.power).toFixed(2),
            ["co2"]: Number(thisInit.co2).toFixed(2),
            ["trees"]: Number(thisInit.trees).toFixed(2),
            ["hours"]: Number(thisInit.hours).toFixed(2),
            planned: Number(thisInit.planned).toFixed(2),
          });
      });
      setBarData(dataInit.sort((a, b) => a.subject - b.subject));
    }
  };

  const calcData = () => {
    let outputDataperDevice = [];
    let total = { hours: 0, power: 0, co2: 0, trees: 0, planned: 0 };
    monthTimeLog &&
      monthTimeLog.map((log) => {
        const start = moment(log.start);
        let plannedLog = 0;
        let end = moment();
        if (log.end) {
          end = moment(log.end);
        }
        const durationLogFull = moment.duration(end.diff(start));
        const secondFull = durationLogFull.asHours();

        //lets aggregate planned
        if (log.attr && log.attr.planned) {
          plannedLog = Number(log.attr.planned) / 60;
          plannedLog = secondFull - plannedLog;
        }

        if (log.devices && log.devices.length > 0) {
          log.devices.map((device) => {
            let thisPower = device.power;
            let thisCo2 = 0;

            const thisCountryData = co2.data.find(
              (c) => loggedIn?.country?.toLowerCase() === c.name.toLowerCase()
            );

            switch (device.type) {
              // case 'bus':
              //   thisCo2 = Number(device.usage) * 2.28
              //   break
              // case 'car':
              //   thisCo2 = Number(device.usage) * 3.09
              //   break
              // case 'airplane':
              //   thisCo2 = Number(device.usage) * 0.11
              //   break
              // case 'playing':
              //   //lets say its 1000 kwh
              //   thisPower = Number(device.usage) * Number(device.power || 1000)
              //   break
              default:
                thisPower = Number(device.power) || 0 * secondFull || 0;
                // thisCo2 = thisPower * 0.31552; //kw*kg
                thisCo2 +=
                  thisPower * (thisCountryData?.value / 1000 || 0.31552);
                if (!outputDataperDevice[device.name])
                  outputDataperDevice[device.name] = {
                    hours: 0,
                    power: 0,
                    trees: 0,
                    co2: 0,
                    planned: 0,
                  };
                total.hours += Number(secondFull);
                total.planned += Number(plannedLog);
                total.power += Number(thisPower) || 0;
                total.co2 += Number(thisCo2) || 0;
                total.trees += thisCo2 * 0.00714;
                outputDataperDevice[device.name].hours += Number(secondFull);
                outputDataperDevice[device.name].power += thisPower;
                outputDataperDevice[device.name].co2 += Number(thisCo2);
                outputDataperDevice[device.name].trees += thisCo2 * 0.00714;

                break;
            }
          });
        }
      });
    return { outputDataperDevice, total };
  };

  const RenderStats = () => {
    return (
      <Flex
        width={"100%"}
        justifyContent={"space-around"}
        mb={"3em"}
        flexDirection={["column", "row"]}
        flexWrap={"wrap"}
      >
        <Box width={{ base: "100%", md: "50%" }} m={".5em 0"}>
          <Board active={true} margin={"0 1em 0 0"} width={"100%"}>
            <Flex alignItems={"center"}>
              <ImPowerCord color={"white"} size={45} />
              <Box ml={".5em"} fontSize={"1.2em"}>
                <MetricValueWhite>
                  {yearTimeLog && (yearTimeLog?.total?.power / 1000).toFixed(1)}
                  <span>Mw/h</span>
                </MetricValueWhite>
              </Box>
            </Flex>
          </Board>
        </Box>
        <Box dark={true} width={{ base: "100%", md: "50%" }} m={".5em 0"}>
          <Board active={true} margin={"0 1em 0 0"} width={"100%"}>
            <Flex alignItems={"center"}>
              <FaSmog color={"white"} size={45} />
              <Box ml={".5em"} fontSize={"1.2em"}>
                <MetricValueWhite>
                  {yearTimeLog && yearTimeLog?.total?.co2.toFixed(1)}
                  <span>Kg CO2</span>
                </MetricValueWhite>
              </Box>
            </Flex>
          </Board>
        </Box>
        {/* <Box width={["100%"]}>
          <Board active={true} margin={"0 1em 0 0"} width={"100%"}>
            <Flex alignItems={"center"}>
              <RiGamepadLine size={45} />
              <Box ml={".5em"} fontSize={"1.2em"}>
                <MetricValueWhite>
                  {yearTimeLog && yearTimeLog?.total?.hours.toFixed(2)}
                  <span>Hours</span>
                </MetricValueWhite>
              </Box>
            </Flex>
          </Board>
        </Box> */}
        <Box dark={true} width={{ base: "100%", md: "50%" }} m={".5em 0"}>
          <Board active={true} margin={"0 1em 0 0"} width={"100%"}>
            <Flex alignItems={"center"}>
              <SiGumtree color={"white"} size={45} />
              <Box ml={".5em"} fontSize={"1.2em"} lineHeight={0.8}>
                <MetricValueWhite>
                  {yearTimeLog && yearTimeLog?.total?.trees.toFixed(1)}{" "}
                  <span>Trees</span>
                </MetricValueWhite>
              </Box>
            </Flex>
          </Board>
        </Box>{" "}
        <Box dark={true} width={{ base: "100%", md: "50%" }} m={".5em 0"}>
          <Board active={true} margin={"0 1em 0 0"} width={"100%"}>
            <Flex alignItems={"center"}>
              <MdRecycling size={45} color="white" />
              <Box ml={".5em"} fontSize={"1.2em"}>
                <MetricValueWhite>
                  {offsets
                    ? offsets
                        .reduce((acc, curr) => {
                          if (curr?.value) {
                            return acc + Number(curr.value || 0) / 1000;
                          }
                          return acc;
                        }, 0)
                        .toFixed(1)
                    : 0}
                  <span>Ton CO2</span>
                </MetricValueWhite>
              </Box>
            </Flex>
          </Board>
        </Box>
      </Flex>
    );
  };

  const RenderMonthData = () => {
    const { outputDataperDevice, total } = calcData();
    return (
      <>
        <Status outputDataperDevice={outputDataperDevice} total={total} />
        <Box mt={"2em"} />
        {Object.keys(outputDataperDevice) &&
          Object.keys(outputDataperDevice).map((deviceKey) => {
            const thisData = outputDataperDevice[deviceKey];
            return (
              <Board
                width="100"
                key={deviceKey}
                percentage={(thisData.hours * 100) / total.hours}
              >
                <Flex alignItems={"center"}>
                  <div>
                    {thisData.type === "console" && (
                      <Joypad fill={"#fff"} height={30} />
                    )}
                    {thisData.type === "tv" && (
                      <Tv height={30} width={50} fill={"#fff"} />
                    )}
                    {thisData.type === "computer" && (
                      <Mouse fill={"#fff"} height={35} />
                    )}
                  </div>
                  <DeviceName>
                    {deviceKey}{" "}
                    <span>
                      {(thisData.hours || 0).toFixed(2)}h{" / "}
                      {(thisData.power || 0).toFixed(2)}kw{" "}
                    </span>
                  </DeviceName>
                </Flex>
              </Board>
            );
          })}
      </>
    );
  };

  const RenderPieOffset = () => {
    const totalConsumed = yearTimeLog?.total?.co2 || 0;
    const totalOffset = offsets?.reduce((acc, curr) => {
      if (curr?.value) {
        return acc + Number(curr.value || 0);
      }
      return acc;
    }, 0);
    //get percentage of offset from total consumed co2
    let percentage = (totalOffset / totalConsumed) * 100;
    if (percentage > 100) {
      percentage = 100;
    } else if (!percentage || percentage < 0) {
      percentage = 0;
    }
    return (
      <RotatedCard
        padding="0"
        background={`linear-gradient(180deg, rgba(27, 124, 95, 0.8) 0%, rgba(27, 124, 95, 0.8) ${
          percentage > 90 ? percentage : percentage - 20
        }%, rgba(201, 55, 0, 0.8) ${percentage}%)`}
        border={"8px solid #e8fff8"}
      >
        <Flex
          width={"100%"}
          height={{ base: "30vh", sm: "140px", md: "30vh" }}
          minH={{ md: "340px" }}
          fontSize={{ base: ".8em", sm: "1em", md: "1em" }}
          flexDirection={{ base: "column", sm: "row", md: "column" }}
        >
          <Flex
            width={"100%"}
            height={{ base: "100%" }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Flex alignItems={"center"} flexDir={"column"}>
              <MdRecycling size={50} color={"white"} />
              <Box ml={".5em"} fontSize={"1.4em"}>
                <MetricValueWhite>
                  {offsets
                    ? offsets
                        .reduce((acc, curr) => {
                          if (curr?.value) {
                            return acc + Number(curr.value || 0) / 1000;
                          }
                          return acc;
                        }, 0)
                        .toFixed(1)
                    : 0}
                  <span>Ton CO2</span>
                </MetricValueWhite>
              </Box>
            </Flex>
          </Flex>
          <Flex
            width={"100%"}
            height={{ base: "100%" }}
            alignItems={"center"}
            justifyContent={"center"}
            position={"relative"}
            background={"#e8fff8"}
            fontSize={{ base: ".8em", lg: "1em" }}
          >
            <MetricValue>
              <Box opacity={".5"} color={"#353f55"}>
                Offset
              </Box>{" "}
              <Box fontSize={"2.4em"} color={"#353f55"}>
                {percentage?.toFixed(1)}%
              </Box>
            </MetricValue>
          </Flex>
          <Flex
            width={"100%"}
            height={{ base: "100%" }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Box>
              <Flex alignItems={"center"} flexDir={"column"}>
                <FaSmog size={50} color={"white"} />
                <Box ml={".5em"} fontSize={"1.4em"}>
                  <MetricValueWhite>
                    {yearTimeLog &&
                      ((yearTimeLog?.total?.co2 || 0) / 1000).toFixed(1)}
                    <span>Ton CO2</span>
                  </MetricValueWhite>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </RotatedCard>
    );
  };

  const ref = React.useRef(null);
  return (
    <Wrap className={`dashboard ${printing ? "printing" : ""}`} ref={ref}>
      <Header
        image={"/images/background.jpg"}
        height={90}
        mheight={70}
        minHeight={600}
      >
        <Flex
          alignContent={"space-between"}
          width={"100%"}
          flexDirection={["column", "column", "row", "row", "row"]}
        >
          <Box
            width={["100%", "100%", "60%", "60%", "60%"]}
            padding={["0", "1em 0", "0", "0", "0"]}
            marginBottom={"2em"}
          >
            <Timer />
          </Box>
          <Box
            display={["none", "none", "block", "block", "block"]}
            width={["100%", "100%", "40%", "40%", "40%"]}
            mt={["5vh", "5vh", "5vh", "5vh", "5vh"]}
          >
            <RenderMonthData />
          </Box>
        </Flex>
      </Header>
      <Box className={"wrapper"} mb={"10vh"}>
        <RowDashPage>
          <Box
            display={["block", "block", "none", "none", "none"]}
            width={["100%", "100%", "100%", "40%", "40%"]}
            paddingBottom={["0vh", "0vh", "0%", "0%", "0%"]}
          >
            <RenderMonthData />
            {/* {client ? <RenderResults /> : <RenderMonthData />} */}
          </Box>

          <Box width={["60%"]} mt={"2em"}>
            <Board margin={"0 1em 0 0"} width={"100%"}>
              <Flex alignItems={"center"} width={"100%"}>
                <RiGamepadLine size={45} />
                <Box ml={".5em"} fontSize={"1.1em"} width={"100%"}>
                  <MetricValue>
                    {getLevelName()}
                    <span>
                      {(yearTimeLog?.totalPointsAveragePerUser || 0).toFixed(1)}{" "}
                      team pts
                    </span>
                    <Tooltip
                      label={`Next level reached in ${(
                        getNexLevelPoints() || 0
                      ).toFixed(1)} points`}
                    >
                      <Box>
                        <Progress
                          mt={"0.2em"}
                          value={getCurrentLevelProgress()}
                          borderRadius={"1em"}
                          colorScheme={"red"}
                        />
                      </Box>
                    </Tooltip>
                  </MetricValue>
                </Box>
              </Flex>
            </Board>
            <RenderStats />
          </Box>

          <Box
            width={{ base: "100%", md: "30%" }}
            mt={{ base: 0 }}
            display={["block"]}
          >
            <RenderPieOffset />
          </Box>
        </RowDashPage>
        <Box width={["100%", "100%", "100%", "100%", "100%"]}>
          <Heading
            size={"md"}
            mb={".6em"}
            textAlign={["center", "center", "left", "left", "left"]}
            color={colors.primary[500]}
          >
            Latest Tasks
          </Heading>

          <Flex width={"100%"} justifyContent={"flex-start"} flexWrap={"wrap"}>
            {requests &&
              requests.map((request, i) => {
                let thisUser;
                if (users) {
                  thisUser = users.find((c) => c._id === request.owner);
                }
                const commentNumber =
                  request && request.history ? request.history.length : 0;
                return (
                  <Board key={i} margin={"0 3% 3% 0"}>
                    <Link to={`/requests/${request._id}`}>
                      {request && (request.image || request.file) && (
                        <Box
                          height={"15vh"}
                          margin={"-1em -1em 1em -1em"}
                          background={`url(${request.image || request.file})`}
                          backgroundPosition={"center"}
                          backgroundSize={"cover"}
                          border={".3em solid white"}
                          borderRadius={"1em 1em 0 0"}
                        />
                      )}
                      <Flex
                        justifyContent={"space-between"}
                        width={"100%"}
                        alignItems={"center"}
                      >
                        <Flex
                          alignContent={"center"}
                          className={"content"}
                          alignItems={"center"}
                        >
                          <Box marginRight={"1em"} width={"40px"}>
                            {request.image ? (
                              <Box
                                borderRadius={"50%"}
                                height={"4em"}
                                width={"4em"}
                                background={`url(${request.image})`}
                                backgroundPosition={"center"}
                                backgroundSize={"cover"}
                                border={".3em solid white"}
                              />
                            ) : (
                              <CommentNumber>
                                {commentNumber || 1}
                              </CommentNumber>
                              // <Icon
                              //   as={GoTasklist}
                              //   color={'white'}
                              //   height={'2em'}
                              //   width={'2em'}
                              // />
                            )}
                          </Box>
                          <Box>
                            <PlayerValue>
                              {request.name}
                              <span>
                                {request.points ? `${request.points} pts` : ""}
                                {" / "}
                                {moment(request.date).format(
                                  "DD/MM/YYYY HH:mm"
                                )}
                              </span>
                              <span>
                                {thisUser
                                  ? `${thisUser.firstName} ${thisUser.lastName}`
                                  : ""}{" "}
                              </span>
                            </PlayerValue>
                          </Box>
                        </Flex>

                        {thisUser && thisUser.image && (
                          <Box
                            borderRadius={"50%"}
                            height={"4.5em"}
                            width={"4.5em"}
                            background={`url(${thisUser.image})`}
                            backgroundPosition={"center"}
                            border={".3em solid white"}
                            backgroundSize={"cover"}
                          />
                        )}
                      </Flex>
                    </Link>
                  </Board>
                );
              })}
          </Flex>
        </Box>
      </Box>
    </Wrap>
  );
}
