import React, { useState } from "react";
import "./Login.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Box, Button, Flex, Input, Link, useToast } from "@chakra-ui/react";
import { setGlobalState, setStorageState } from "../store";
function Login(props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const toast = useToast();
  setGlobalState("pageClass", "login");

  const resetPassword = async e => {
    if (!username || username === "") {
      toast({
        title: "Please enter your email in the field first",
        status: "warning",
        position: "bottom-left"
      });
    }

    await axios.post(`/auth/forgot`, {
      email: username
    });
    toast({
      title: "Check your email",
      status: "warning",
      position: "bottom-left"
    });
  };

  const loginUser = async e => {
    e.preventDefault();

    if (username === "" || password === "") {
      return toast({
        title: "Please check your email and password",
        status: "warning",
        position: "bottom-left"
      });
    }

    try {
      const response = await axios.post(`/auth`, {
        email: username,
        password
      });
      if (response.data && response.data.token) {
        await setStorageState("loggedIn", response.data);
        props.history.push("/dashboard");
      }
    } catch (e) {
      console.log(e);
      setStorageState("loggedIn", null);
      toast({
        title: "Verify you details",
        status: "warning",
        position: "bottom-left"
      });
    }
  };

  return (
    <Flex
      align="center"
      alignItems="center"
      justifyContent={["start", "start", "center", "center", "center"]}
      flexDirection="column"
      height="100vh"
    >
      <Box
        className={"loginwrap"}
        minHeight={["50vh", "50vh", "100vh", "100vh", "100vh"]}
        width={["100%", "100%", "45%", "35%", "35%"]}
        padding={"2em"}
        shadow="md"
        borderWidth="0"
        rounded="lg"
        position={"absolute"}
        bottom={["0", "0", "0", "0", "0"]}
        // background={'url(/images/login.svg)'}
        // backgroundPosition={'right'}
        left="0"
      >
        <div className={"login-container"}>
          <div>
            <Box
              backgroundImage={"url(/images/logo.png)"}
              backgroundSize={"contain"}
              backgroundPosition={"center"}
              backgroundRepeat={"no-repeat"}
              height={["5em", "5em", "6em", "6em", "6em"]}
              margin={["1em 0", "2em 0", "3em 0", "3em 0", "3em 0"]}
            />
            <form className="login-form" onSubmit={loginUser} data-login-form>
              <Input
                data-login-email
                onChange={e => setUsername(e.target.value)}
                placeholder="Email"
                colorScheme="primary"
                size="lg"
              />
              <Input
                type="password"
                data-login-password
                onChange={e => setPassword(e.target.value)}
                placeholder="Password"
                colorScheme="primary"
                size="lg"
              />

              <Button
                onClick={e => loginUser(e)}
                colorScheme="secondary"
                size="lg"
                value="Submit!"
                width={"100%"}
                _hover={{
                  background: "primary.500"
                }}
              >
                Login
              </Button>
            </form>
            <Flex
              alignItems={"center"}
              justifyContent={"center"}
              alignContent={"center"}
            >
              <Link
                href={"/register"}
                fontSize={"1em"}
                margin={"1em"}
                color={"white"}
                textAlign={"center"}
                _hover={{
                  color: "secondary.500"
                }}
              >
                Register
              </Link>
              <Link
                href={"#"}
                fontSize={"1em"}
                margin={"1em"}
                color={"white"}
                textAlign={"center"}
                onClick={() => resetPassword()}
                _hover={{
                  color: "secondary.500"
                }}
              >
                Reset Password
              </Link>
            </Flex>
          </div>
        </div>
      </Box>
      <Box
        width={["100%", "100%", "50%", "40%", "40%"]}
        className={"loginBackground"}
      ></Box>
      <Box
        marginLeft={["0%", "0%", "35%", "35%", "35%"]}
        marginTop={["0", "0", "0%", "0%", "0%"]}
        width={["100%", "100%", "65%", "65%", "65%"]}
        height={["50%", "50%", "100%", "100%", "100%"]}
        backgroundImage={"url(/images/home.jpg)"}
        backgroundSize={"cover"}
        backgroundPosition={[
          "center 50%",
          "center 50%",
          "center",
          "center",
          "center"
        ]}
        backgroundRepeat={"no-repeat"}
      >
        {/* <Heading
          as="h3"
          fontSize={['0.9rem', '1.0rem', '1.2rem', '1.5rem', '1.5rem']}
          color={'white'}
        >
          A SMALL STEP FOR MAN,
        </Heading>
        <Heading
          as="h1"
          fontSize={['2.8rem', '4rem', '5rem', '7rem', '7rem']}
          color={'white'}
        >
          A GIANT LEAP FOR GAMING!
        </Heading> */}
      </Box>
    </Flex>
  );
}

export default withRouter(Login);
