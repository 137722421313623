import styled from "styled-components";
import { colors } from "../theme";

export const Wrap = styled.div``;

export const RotatedCard = styled.div`
  && {
    background: radial-gradient(
        circle at center right,
        rgba(255, 255, 255, 0.8) 50%,
        rgba(255, 255, 255, 0.9) 100%
      ),
      url("images/pattern.svg") no-repeat;
    background-size: auto, cover;
    backdrop-filter: saturate(110%) blur(15px);

    /* background: white; */
    box-shadow: rgb(0 0 0 / 25%) 0px 3.5px 5.5px;
    /* backdrop-filter: saturate(130%) blur(10px); */
    /* background: #ffffff50; */
    max-width: 1240px;
    border-radius: 55px;
    padding: 2em;
    margin: 2vh 0 0 0 2vw;
    width: 100%;
    @media screen and (max-width: 560px) {
      background: radial-gradient(
          circle at center right,
          rgba(255, 255, 255, 0.8) 50%,
          rgba(255, 255, 255, 0.9) 100%
        );
      padding: 1em;
    }
    @supports not (-webkit-touch-callout: none) {
      transform: perspective(10vw) rotateX(-1deg) rotateY(1deg);
      @media screen and (max-width: 560px) {
        transform: perspective(10vw) rotateX(-0.5deg) rotateY(0.5deg);
      }
    }
    ${props => (props.border ? `border:${props.border};` : "")}
    ${props => (props.padding ? `padding:${props.padding};` : "padding: 2em;")}
    ${props =>
      props.background
        ? `background:${props.background};
          @media screen and (max-width: 560px) {
            background:${props.background};
            padding: 0;
           }
    `
        : `background:radial-gradient(
        circle at center right,
        rgba(255, 255, 255, 0.8) 50%,
        rgba(255, 255, 255, 0.9) 100%
      )`}
  }
`;
export const Board = styled.div`
  && {
    width: 100%;
    border: none;
    background: #fff;
    padding: 1em;
    margin: 0 1em 1em 0;
    border-radius: 2em;
    box-shadow: 0 2px 9px 0 ${colors.primary.transparent[20]}; 
    background-color: #fff;
    ${props => props.height && `height:${props.height};`}

    background: ${colors.primary[500]}; 
    background-image: linear-gradient(-32deg, #191e26 0%, #353f55 100%);
    box-shadow: -10px -10px 35px 0 ${colors.primary.transparent[20]}
      15px 15px 40px 0 ${colors.primary.transparent[20]},
      inset 1px 1px 0 0 ${colors.primary.transparent[20]};
    border-radius: 30px;

    @media screen and (max-width: 1200px) {
    }
  }
`;
export const DeviceName = styled.div`
  && {
    color: #fff;
    font-size: 1.4em;
    font-weight: bold;
    font-style: italic;
    line-height: 1;
    margin-left: 0.6em;
    display: block;
    width: 100%;
    span {
      opacity: 0.6;
      font-size: 0.8em;
    }
  }
`;
export const Row = styled.div`
  && {
    display: flex;
    width: 100%;
    margin: 2em 0;
  }
`;
export const RowDashPage = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    margin: 0 0 0;

    @media screen and (max-width: 890px) {
      flex-direction: column;
      > div {
        width: 100%;
        max-width: 100%;
      }
    }
  }
`;
export const DashFilter = styled.div`
  && {
    font-size: 0.75em;
    input[type="date"]::-webkit-calendar-picker-indicator {
      cursor: pointer;
      margin: 0;
      border-radius: 4px;
      margin-right: 2px;
      filter: invert(1);
    }
  }
`;

/**
 * Assessment styles
 */

export const Line = styled.div`
  && {
    color: ${colors.secondary[500]};
    font-size: 0.8em;
    span {
      color: ${colors.primary[500]};
    }
  }
`;
export const AssessmentDetails = styled.div`
  && {
    margin: 1em 0;
  }
`;

export const ClientMetricsHistory = styled.div`
  && {
    display: block;
    color: ${colors.primary[500]};
    font-weight: bold;
    font-size: 1.6em;
    span {
      color: ${colors.primary[200]};
      font-weight: lighter;
    }
  }
`;

export const ClientMetricsOutline = styled.div`
  && {
    display: block;
    border: 1px solid ${colors.primary[500]};
    background: transparent;
    padding: 0.3em 0.8em;
    margin: 0.2em;
    border-radius: 2em;
    color: ${colors.primary[500]};
    font-weight: bold;
    font-size: 0.8em;
    span {
      color: ${colors.primary[200]};
      font-weight: lighter;
    }
  }
`;

export const ClientTargetOutline = styled.div`
  && {
    display: flex;
    align-items: center;
    border: 1px solid #569535;
    background: transparent;
    padding: 0.3em 0.8em;
    margin: 0.2em;
    border-radius: 2em;
    color: #569535;
    font-weight: bold;
    font-size: 0.8em;
    span {
      color: ${colors.primary[200]};
      font-weight: lighter;
    }
  }
`;
export const ClientInitiativesOutline = styled.div`
  && {
    display: flex;
    align-items: center;
    border: 1px solid ${colors.secondary[500]};
    background: transparent;
    padding: 0.3em 0.8em;
    margin: 0.2em;
    border-radius: 2em;
    color: ${colors.secondary[500]};
    font-weight: bold;
    font-size: 0.8em;
    span {
      color: ${colors.primary[200]};
      font-weight: lighter;
    }
  }
`;
export const ClientMetrics = styled.div`
  && {
    display: block;
    background: ${colors.primary[500]};
    padding: 0.3em 0.8em;
    margin: 0.2em;
    border-radius: 2em;
    color: ${colors.primary[100]};
    font-weight: bold;
    font-size: 0.8em;
    span {
      color: ${colors.primary[100]};
      font-weight: lighter;
    }
  }
`;
export const HistoryCard = styled.div`
  && {
    cursor: pointer;
    width: 100%;
    border: none;
    text-align: left;
    background: #fff;
    padding: 1em;
    margin: 0 1% 1% 0;
    border-radius: 2em;
    /* min-height: 8em; */
    /* border: 1px solid ${colors.primary[200]}; */
    box-shadow: 0 2px 9px 0 ${colors.primary.transparent[20]};
  }
`;
