import React from "react";
import {
  Select,
  InputGroup,
  Input,
  Textarea,
  Box,
  Text,
  FormControl,
  FormLabel,
  Switch
} from "@chakra-ui/react";
import Upload from "../../components/Upload";
import { getGlobalState, setGlobalState } from "../../store";

/**
 * Table collumns
 */
export const tableCollumns = [
  { k: "_id", l: "_id" },
  { k: "dark", l: "dark", t: "switch" },
  { k: "image", l: "image", t: "upload" },
  { k: "firstName", l: "First Name" },
  { k: "lastName", l: "Last Name" },
  { k: "email", l: "Email" },
  { k: "phone", l: "Phone" },
  { k: "age", l: "Age" },
  { k: "country", l: "Country", t: "country" },
  { k: "city", l: "City" },
  { k: "password", l: "Password", t: "password" },
  { k: "admin", l: "admin" },
  { k: "status", l: "Status" },
  { k: "role", l: "Role" }
  // { k: 'location', l: 'Localização', t: 'map' }
  // { k: 'attr', l: 'Atributos' },
];
const RenderEdit = props => {
  const loggedIn = getGlobalState("loggedIn");
  const { handleFormFieldChange, types, details } = props;
  const isAdmin = loggedIn && loggedIn.admin;
  return (
    tableCollumns &&
    tableCollumns.map((k, i) => {
      if (k.k === "_id") return null;
      if (!isAdmin && (k.k === "admin" || k.k === "status" || k.k === "role"))
        return null;
      if (k.k === "role" && types)
        return (
          <InputGroup key={i} margin={"1em 0"} size="sm">
            <Select
              height="3em"
              onChange={event => handleFormFieldChange(k.k, event)}
              placeholder="Select a Role"
              value={details[k.k]}
            >
              {types.map(
                (t, a) =>
                  t.status === 1 && (
                    <option key={a} value={t._id}>
                      {t.name}
                    </option>
                  )
              )}
            </Select>
          </InputGroup>
        );
      if (k.k === "admin")
        return (
          <InputGroup key={i} margin={"1em 0"} size="sm">
            <Select
              height="3em"
              onChange={event => handleFormFieldChange(k.k, event)}
              placeholder="Is an Admin"
              value={details[k.k]}
            >
              <option value={1}> Is Admin </option>
              <option value={0}> Not Admin </option>
            </Select>
          </InputGroup>
        );

      if (k.t === "switch") {
        return (
          <FormControl
            key={i}
            display="flex"
            justifyContent={"flex-end"}
            alignItems="center"
          >
            <FormLabel htmlFor="default-device" mb="0">
              {details?.attr?.darkMode === "dark" ? "Light Mode" : "Dark Mode"}
            </FormLabel>
            <Switch
              id="default-device"
              isChecked={details?.attr?.darkMode === "dark" || false}
              onChange={event => {
                setGlobalState(
                  "darkMode",
                  event.target.checked ? "dark" : "light"
                );
                handleFormFieldChange("attr", {
                  target: {
                    value: {
                      ...(details.attr || {}),
                      darkMode: event.target.checked ? "dark" : "light"
                    }
                  }
                });
              }}
            />
          </FormControl>
        );
      }
      if (k.t === "upload") {
        return (
          <Box key={i} margin={"2em 0"}>
            {/* {details[k.k] && (
                <img
                  style={{
                    width: '100%',
                    height: '20vh',
                    objectFit: 'contain'
                  }}
                  src={details[k.k]}
                />
              )} */}
            <Upload
              key={i}
              callback={files =>
                handleFormFieldChange(k.k, { target: { value: files } })
              }
            />
          </Box>
        );
      }
      if (k.k === "status")
        return (
          <InputGroup key={i} margin={"1em 0"} size="sm">
            <Select
              onChange={event => handleFormFieldChange(k.k, event)}
              placeholder="Status"
              value={details[k.k]}
            >
              <option value={0}> Inactive </option>
              <option value={1}> Active </option>
            </Select>
          </InputGroup>
        );

      if (k.t === "textarea") {
        return (
          <InputGroup key={i} margin={"1em 0"} size="sm">
            <Textarea
              padding={"1.5em .5em"}
              onChange={event => handleFormFieldChange(k.k, event)}
              placeholder={k.l}
              type={k.t}
              value={details[k.k]}
            />
          </InputGroup>
        );
      }
      if (k.t === "dateArray") {
        return (
          <div key={i}>
            <Text fontSize={".8em"} fontWeight={"bold"}>
              Indisponibilidade
            </Text>
          </div>
        );
      }
      if (k.t === "country") {
        return (
          <div key={i}>
            <Input
              key={i}
              size="lg"
              onChange={event => handleFormFieldChange(k.k, event)}
              type={k.t || "text"}
              list="country"
              placeholder={k.l}
              value={details[k.k]}
            />
            <datalist id="country">
              <option value="portugal" />
              <option value="italy" />
              <option value="poland" />
            </datalist>
          </div>
        );
      }
      return (
        <Input
          key={i}
          size="lg"
          onChange={event => handleFormFieldChange(k.k, event)}
          type={k.t || "text"}
          placeholder={k.l}
          value={details[k.k]}
        />
      );
    })
  );
};
export default RenderEdit;
