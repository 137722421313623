import React from "react";

function Team(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "511pt"}
      height={props.height || "511pt"}
      version="1.1"
      viewBox="-66 0 511 511.999"
    >
      <path
        fill="#ffda2d"
        d="M200.06 48.082c-99.41 0-180 80.59-180 180 0 99.406 80.587 180 180 180 99.391 0 180-80.57 180-180 0-99.41-80.593-180-180-180zm0 0"
      ></path>
      <g
        fill="#1a1a1a"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        transform="matrix(12.13428 0 0 12.13428 -1931.185 -1708.545)"
      >
        <path
          d="M17 31.127574L47 10 31.942474 44 27.339265 43.124476 27.851058 40.28803 30.29421 40.752514 40.262176 18.243618 20.40152 32.230386 21.271276 34.619762 18.639199 35.629797z"
          transform="matrix(.75147 0 0 .75147 152.936 137.005)"
        ></path>
        <path
          d="M12.214583 48.823169L23.678671 41.449274 25 35 18.20519 37.296506 12.16432 48.785006 12 48.961254 12.107936 48.89192 12.050907 49z"
          transform="matrix(.75147 0 0 .75147 152.936 137.005)"
        ></path>
        <path
          d="M45 24.422636L47.244428 26.553106 45.931559 29.366922 48.631509 27.86953 50.875938 30 50.299747 26.944286 53 25.446894 49.944078 25.055714 49.368491 22 48.055621 24.813816z"
          transform="matrix(.75147 0 0 .75147 152.936 137.005)"
        ></path>
        <g transform="matrix(.55029 0 0 .55029 77.966 80.753) matrix(.75147 0 0 .75147 169.983 144.08)">
          <path d="M27.339265 43.124476L27.851058 40.28803 30.29421 40.752514 40.262176 18.243618 20.40152 32.230386 21.271276 34.619762 18.639199 35.629797 17 31.127574 47 10 31.942474 44z"></path>
          <path d="M12 48.961254L12.107936 48.89192 12.050907 49 12.214583 48.823169 23.678671 41.449274 25 35 18.20519 37.296506 12.16432 48.785006z"></path>
        </g>
        <g transform="matrix(.55029 0 0 .55029 57.226 61.96) matrix(.75147 0 0 .75147 169.983 144.08)">
          <path d="M21.271276 34.619762L18.639199 35.629797 17 31.127574 47 10 31.942474 44 27.339265 43.124476 27.851058 40.28803 30.29421 40.752514 40.262176 18.243618 20.40152 32.230386z"></path>
          <path d="M25 35L18.20519 37.296506 12.16432 48.785006 12 48.961254 12.107936 48.89192 12.050907 49 12.214583 48.823169 23.678671 41.449274z"></path>
        </g>
      </g>
    </svg>
  );
}

export default Team;
