import React, { useState } from 'react'

import { setGlobalState } from '../store'
import Iframe from 'react-iframe'

export default function Trello(props) {
  setGlobalState('pageClass', 'trello')

  return (
    <div className={'trello dashboard'} style={{ height: '100vh' }}>
      <Iframe
        url="https://trello.com/b/nC8QJJoZ.html"
        width="100%"
        height="100%"
        style={{ height: '100vh' }}
      />
    </div>
  )
}
