import React from 'react'

const User = props => {
  return (
    <svg
      width={36}
      height={39}
      viewBox="0 0 36 39"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{'Group'}</title>
      <g
        transform="translate(.562 .354)"
        fill={props.fill || '#C5C5C5'}
        fillRule="nonzero"
      >
        <path d="M25.361 23H10.04C4.595 23 .2 27.291.2 32.606V38h35v-5.394c0-5.315-4.395-9.606-9.839-9.606z" />
        <circle cx={18.2} cy={10} r={10} />
      </g>
    </svg>
  )
}
export default User
