import React, { useEffect, useState } from "react";
import { Button, Box, Flex } from "@chakra-ui/react";
import { setRemoteCall } from "../remote";

import { useGlobalState, getGlobalState, setStorageState } from "../store";

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton
} from "@chakra-ui/react";
import { PlayerImage, PlayerName } from "./styled";
import View from "./Tabs/View";
import RenderEdit from "./Tabs/Edit";
import HistoryView from "./Tabs/History";
import RenderDevices from "./Tabs/Devices";

/**
 * Table collumns
 */
export const tableCollumns = [
  { k: "_id", l: "_id" },
  { k: "firstName", l: "First Name" },
  { k: "lastName", l: "Last Name" },
  { k: "email", l: "Email" },
  { k: "password", l: "Password", t: "password" }
  // { k: 'location', l: 'Localização', t: 'map' }
  // { k: 'attr', l: 'Atributos' },
];

export default function Edit(props) {
  const [loggedIn, setLoggedIn] = useGlobalState("loggedIn"); //dont remove
  const [userDetails, setUserDetails] = useGlobalState("editProfile"); //dont remove
  const [details, setDetails] = useState({});
  const types = getGlobalState("userTypes");

  const [view, setView] = useState("view");
  // const projects = getGlobalState('projects')
  // const relations = getGlobalState('relations')

  const handleFormFieldChange = (key, { target: { value } }) => {
    setDetails({ ...details, [key]: value });
  };

  /**
   * Use effects
   */
  const setUser = () => {
    setRemoteCall("setUser", details, null, data => {
      setDetails(data);
      if (data._id === loggedIn._id) {
        setLoggedIn({ ...loggedIn, ...data });
        setStorageState("loggedIn", { ...loggedIn, ...data });
      }
    });
  };

  /**
   * Use effects
   */
  useEffect(() => {
    if (loggedIn && userDetails) {
      setRemoteCall("getUser", {}, null, data => {
        if (!data) return null;
        setDetails(data);
        if (data._id === loggedIn._id) {
          // setLoggedIn({ ...loggedIn, ...data });
          // setStorageState("loggedIn", { ...loggedIn, ...data });
        }
      });
    }
  }, [userDetails]);

  if (!details) return null;

  const RenderHeader = ({ height = 40 }) => {
    return (
      <Box margin={"-.5rem -1.5rem"}>
        <PlayerImage image={details.image} height={height}>
          <Navigation />
          <Flex
            justifyContent={"left center"}
            alignItems={"center"}
            height={`${height}vh`}
          >
            <PlayerName>
              {details.firstName || ""}
              <br />
              {details.lastName || ""}
            </PlayerName>
          </Flex>
        </PlayerImage>
      </Box>
    );
  };

  const Navigation = () => {
    return (
      <Flex position={"absolute"} top={"1em"} right={"1em"}>
        <Button
          size={"sm"}
          mr={1}
          colorScheme={view === "view" ? "primary" : "white"}
          variant={view === "view" ? "solid" : "outline"}
          onClick={() => setView("view")}
        >
          Profile
        </Button>
        <Button
          size={"sm"}
          colorScheme={view === "devices" ? "primary" : "white"}
          variant={view === "devices" ? "solid" : "outline"}
          mr={1}
          onClick={() => {
            setView("devices");
          }}
        >
          Devices
        </Button>
        <Button
          size={"sm"}
          colorScheme={view === "history" ? "primary" : "white"}
          variant={view === "history" ? "solid" : "outline"}
          mr={1}
          onClick={() => {
            setView("history");
          }}
        >
          History
        </Button>
        <Button
          size={"sm"}
          colorScheme={view === "edit" ? "primary" : "white"}
          variant={view === "edit" ? "solid" : "outline"}
          mr={1}
          onClick={() => {
            setView("edit");
          }}
        >
          Edit
        </Button>
      </Flex>
    );
  };
  return (
    <Drawer
      isOpen={userDetails}
      placement="right"
      isFullHeight={true}
      size={"md"}
      onClose={() => setUserDetails(false)}
      // finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        {/* <DrawerHeader borderBottomWidth="0"></DrawerHeader> */}
        <DrawerBody overflow={"auto"}>
          {view === "edit" && (
            <>
              <RenderHeader />
              <RenderEdit
                handleFormFieldChange={handleFormFieldChange}
                types={types}
                details={details}
              />
            </>
          )}
          {view === "view" && (
            <>
              <RenderHeader height={65} />
              <View details={details} />
            </>
          )}
          {view === "devices" && (
            <>
              <RenderHeader />
              <RenderDevices
                data={details.devices}
                userId={details._id}
                setData={data =>
                  handleFormFieldChange("devices", {
                    target: { value: data }
                  })
                }
              />
            </>
          )}
          {view === "history" && (
            <>
              <RenderHeader />
              <HistoryView userId={details._id} />
            </>
          )}
        </DrawerBody>
        <DrawerFooter borderTopWidth="0">
          <Button
            variant="outline"
            colorScheme="primary"
            mr={3}
            onClick={() => setUserDetails(false)}
          >
            Cancel
          </Button>
          <Button
            colorScheme="primary"
            onClick={() => {
              setUser({ ...details }, ` updating user`, "users");
              setUserDetails(false);
            }}
          >
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
