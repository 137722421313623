import React from "react";
import { useGlobalState } from "../../store";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

function TableProvider(props) {
  const [loggedIn, setLoggedIn] = useGlobalState("loggedIn"); //dont remove  // const loggedIn = getGlobalState('loggedIn')
  const [newDarkmode, setDarkMode] = useGlobalState("darkMode"); //dont remove  // updating dakmode

  let darkMode = loggedIn?.attr?.darkMode === "dark";

  if (newDarkmode && (newDarkmode === "dark" || newDarkmode === "light")) {
    darkMode = newDarkmode === "dark";
  }
  const getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true,
        fontFamily: "'RiftSoft', sans-serif !important",
        color: darkMode ? "#fff" : "#000",
      },
      overrides: {
        MuiTableBody: {
          root: {
            backgroundColor: "transparent",
          },
        },
        MUIDataTable: {
          root: {
            backgroundColor: "transparent",
            maxHeight: "100%",
            height: "80vh",
          },
          headerCell: {
            backgroundColor: "transparent",
            fontWeight: "bold",
          },
          paper: {
            boxShadow: "none",
            background: "transparent",
          },
          responsiveScroll: {
            maxHeight: "none",
            background: "transparent",
            borderRadius: "4px",
            boxShadow: "none",
          },
        },

        MuiTablePagination: {
          toolbar: {
            color: darkMode ? "#fff" : "#000",
          },
        },
        MUIDataTableBodyRow: {
          responsiveStacked: {
            margin: "1em 0",
          },
        },
        MUIDataTableBodyCell: {
          stackedCommon: {
            whiteSpace: "normal!important",
            height: "auto!important",
            color: darkMode ? "#fff" : "#000",
            fontSize: "1.1em",
          },
        },
        MUIDataTableSelectCell: {
          headerCell: {
            backgroundColor: "transparent",
          },
          fixedHeaderCommon: {
            backgroundColor: "transparent",
          },
        },
        MuiTableCell: {
          head: {
            fontWeight: "bold",
            color: darkMode ? "#fff" : "#000",
            fontSize: "1.2em",
            fontStyle: "italic",
          },
          root: {
            color: darkMode ? "#fff" : "#000",
          },
        },
        MuiIconButton: {
          root: {
            color: darkMode ? "#fff" : "#000",
          },
        },
        MUIDataTableHeadCell: {
          backgroundColor: "transparent",
          fontWeight: "bold",
          fixedHeader: {
            backgroundColor: "transparent",
            zIndex: 9,
          },
          fixedHeaderCommon: {
            backgroundColor: "transparent",
          },
        },
      },
    });
  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable filterType={"dropdown"} {...props} />
    </MuiThemeProvider>
  );
}

export default TableProvider;
