import React, { useEffect } from "react";
import { Board } from "../styled";
import Results from "./Results";
import { useGlobalState, getGlobalState } from "../../store";
import { setRemoteCall } from "../../remote";
import { Heading, Text, Box, Flex, Progress, Tooltip } from "@chakra-ui/react";
import { FaSmog } from "react-icons/fa";
import { BsBarChartFill, BsCalendar4Week } from "react-icons/bs";
import Medal from "../../users/icons/medal";
import Team from "../../users/icons/team";

import { SiGumtree } from "react-icons/si";
import { ImPowerCord } from "react-icons/im";

const Status = ({ total, outputDataperDevice }) => {
  const loggedIn = getGlobalState("loggedIn");
  const [points, setPoints] = useGlobalState("points");

  const getPlayerPoints = (newSearch) => {
    setRemoteCall(
      "getPoints",
      {
        user: loggedIn?._id,
      },
      "",
      (cb) => {
        setPoints(cb);
      }
    );
  };

  useEffect(() => {
    getPlayerPoints();
  }, []);
  return (
    <>
      <Results points={points} />
      <Flex
        justifyContent={"center"}
        mt={"2em"}
        fontSize={{ base: ".8em", xl: "1em" }}
      >
        <Board width={100} margin={"0 .5em 0 0"} mMargin={"0 .5em 0 0"}>
          <Flex justifyContent={"left"} width={"100%"} alignItems={"center"}>
            <Box display={{ base: "none", md: "block" }}>
              <ImPowerCord size={25} />
            </Box>
            <Box ml={".8em"}>
              <Heading
                fontSize={{ base: "1.3em", sm: "1.3em" }}
                lineHeight={1}
                mb={0}
              >
                {total && total.power.toFixed(1)}
              </Heading>
              <Text
                fontSize={".8em"}
                fontWeight={"bold"}
                fontStyle={"italic"}
                lineHeight={1}
                mt={0}
                opacity={".5"}
              >
                Kw/h
              </Text>
            </Box>
          </Flex>
        </Board>

        <Board width={100} margin={"0 .5em 0 0"} mMargin={"0 .5em 0 0"}>
          <Flex justifyContent={"left"} width={"100%"} alignItems={"center"}>
            <Box display={{ base: "none", md: "block" }}>
              <BsCalendar4Week size={25} />
            </Box>
            <Box ml={".8em"}>
              <Heading
                fontSize={{ base: "1.3em", sm: "1.3em" }}
                lineHeight={1}
                mb={0}
              >
                {total && total.planned && total.planned.toFixed(1)}h
              </Heading>
              <Text
                fontSize={".8em"}
                fontStyle={"italic"}
                fontWeight={"bold"}
                lineHeight={1}
                mt={0}
                opacity={".5"}
              >
                {total && total.hours && total.hours.toFixed(1)}h
              </Text>
            </Box>
          </Flex>
        </Board>
        <Board width={100} margin={"0 .5em 0 0"} mMargin={"0 .5em 0 0"}>
          <Flex justifyContent={"left"} width={"100%"} alignItems={"center"}>
            <Box display={{ base: "none", md: "block" }}>
              <FaSmog size={25} />
            </Box>
            <Box ml={".8em"}>
              <Heading
                fontSize={{ base: "1.3em", sm: "1.3em" }}
                lineHeight={1}
                mb={0}
              >
                {total && total.co2 && total.co2.toFixed(1)}
              </Heading>
              <Text
                fontSize={".8em"}
                fontStyle={"italic"}
                fontWeight={"bold"}
                lineHeight={1}
                mt={0}
                opacity={".5"}
              >
                KgCo2
              </Text>
            </Box>
          </Flex>
        </Board>
        <Board width={100} mMargin={"0 .5em 0 0"} margin={"0 .5em 0 0"}>
          <Flex justifyContent={"left"} width={"100%"} alignItems={"center"}>
            <Box display={{ base: "none", md: "block" }}>
              <SiGumtree size={25} />
            </Box>
            <Box ml={".8em"}>
              <Heading
                fontSize={{ base: "1.3em", sm: "1.3em" }}
                lineHeight={1}
                mb={0}
              >
                {total && total.trees && total.trees.toFixed(2)}
              </Heading>
              <Text
                fontSize={".8em"}
                fontWeight={"bold"}
                fontStyle={"italic"}
                lineHeight={1}
                mt={0}
                opacity={".5"}
              >
                trees
              </Text>
            </Box>
          </Flex>
        </Board>
      </Flex>
    </>
  );
};

export default Status;
