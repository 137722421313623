module.exports = {
  data: [
    { name: "Albania", value: 0 },
    { name: "Algeria", value: 486.7 },
    { name: "Angola", value: 238.6 },
    { name: "Argentina", value: 290.9 },
    { name: "Armenia", value: 198 },
    { name: "Australia", value: 649.1 },
    { name: "Austria", value: 131 },
    { name: "Azerbaijan", value: 428.3 },
    { name: "Bahrain", value: 698.7 },
    { name: "Bangladesh", value: 543.7 },
    { name: "Belarus", value: 363.5 },
    { name: "Belgium", value: 129.9 },
    { name: "Benin", value: 510.9 },
    { name: "Plurinational State of Bolivia", value: 306.5 },
    { name: "Bosnia and Herzegovina", value: 684.6 },
    { name: "Botswana", value: 1351 },
    { name: "Brazil", value: 132.8 },
    { name: "Brunei Darussalam", value: 891.2 },
    { name: "Bulgaria", value: 403.1 },
    { name: "Cambodia", value: 493.7 },
    { name: "Cameroon", value: 274.8 },
    { name: "Canada", value: 121.5 },
    { name: "Chile", value: 446.3 },
    { name: "People's Republic of China", value: 619.7 },
    { name: "Colombia", value: 144.2 },
    { name: "Republic of the Congo", value: 580.5 },
    { name: "Democratic Republic of the Congo", value: 0.4 },
    { name: "Costa Rica", value: 0.2 },
    { name: "Cote d'Ivoire", value: 315.7 },
    { name: "Croatia", value: 151.3 },
    { name: "Cuba", value: 675.6 },
    { name: "Curacao/Netherlands Antilles", value: 530.7 },
    { name: "Cyprus", value: 609.6 },
    { name: "Czech Republic", value: 427.3 },
    { name: "Denmark", value: 109.9 },
    { name: "Dominican Republic", value: 532.6 },
    { name: "Ecuador", value: 137.7 },
    { name: "Egypt", value: 382.6 },
    { name: "El Salvador", value: 116.4 },
    { name: "Equatorial Guinea", value: 396.2 },
    { name: "Eritrea", value: 822.6 },
    { name: "Estonia", value: 600.8 },
    { name: "Ethiopia", value: 0.3 },
    { name: "Kingdom of Eswatini", value: 154.8 },
    { name: "Finland", value: 72.4 },
    { name: "France", value: 54.1 },
    { name: "Gabon", value: 579.2 },
    { name: "Georgia", value: 81.5 },
    { name: "Germany", value: 356.1 },
    { name: "Ghana", value: 322.8 },
    { name: "Gibraltar", value: 504.9 },
    { name: "Greece", value: 324.8 },
    { name: "Guatemala", value: 337.3 },
    { name: "Guyana", value: 816.1 },
    { name: "Haiti", value: 815.2 },
    { name: "Honduras", value: 325.4 },
    { name: "Hong Kong", value: 639.1 },
    { name: "Hungary", value: 194.6 },
    { name: "Iceland", value: 0.1 },
    { name: "India", value: 691.5 },
    { name: "Indonesia", value: 769.4 },
    { name: "Islamic Republic of Iran", value: 491.9 },
    { name: "Iraq", value: 663 },
    { name: "Ireland", value: 338.5 },
    { name: "Israel", value: 434.7 },
    { name: "Italy", value: 269 },
    { name: "Jamaica", value: 486.9 },
    { name: "Japan", value: 461.3 },
    { name: "Jordan", value: 390.7 },
    { name: "Kazakhstan", value: 573 },
    { name: "Kenya", value: 61 },
    { name: "Korea", value: 463.6 },
    { name: "Democratic People's Republic of Korea", value: 203.2 },
    { name: "Kosovo", value: 939.4 },
    { name: "Kuwait", value: 614.6 },
    { name: "Kyrgyzstan", value: 54.7 },
    { name: "Lao People's Democratic Republic", value: 343.4 },
    { name: "Latvia", value: 109.6 },
    { name: "Lebanon", value: 707 },
    { name: "Libya", value: 630.9 },
    { name: "Lithuania", value: 136.2 },
    { name: "Luxembourg", value: 98.9 },
    { name: "Madagascar", value: 553.8 },
    { name: "Malaysia", value: 651 },
    { name: "Malta", value: 374.6 },
    { name: "Mauritius", value: 759.8 },
    { name: "Mexico", value: 371 },
    { name: "Republic of Moldova", value: 487.6 },
    { name: "Mongolia", value: 1089.7 },
    { name: "Montenegro", value: 471 },
    { name: "Morocco", value: 710.3 },
    { name: "Mozambique", value: 78.1 },
    { name: "Myanmar", value: 413.4 },
    { name: "Namibia", value: 43.9 },
    { name: "Nepal", value: 0 },
    { name: "Netherlands", value: 313.1 },
    { name: "New Zealand", value: 129.6 },
    { name: "Nicaragua", value: 224.7 },
    { name: "Niger", value: 1015.8 },
    { name: "Nigeria", value: 417.4 },
    { name: "Republic of North Macedonia", value: 556.3 },
    { name: "Norway", value: 4.1 },
    { name: "Oman", value: 391.2 },
    { name: "Pakistan", value: 394.5 },
    { name: "Panama", value: 329.7 },
    { name: "Paraguay", value: 0 },
    { name: "Peru", value: 199.8 },
    { name: "Philippines", value: 708.4 },
    { name: "Poland", value: 640.3 },
    { name: "Portugal", value: 149.2 },
    { name: "Qatar", value: 484.9 },
    { name: "Romania", value: 274.7 },
    { name: "Russian Federation", value: 359 },
    { name: "Rwanda", value: 251.6 },
    { name: "Saudi Arabia", value: 610.5 },
    { name: "Senegal", value: 556.5 },
    { name: "Serbia", value: 695.6 },
    { name: "Singapore", value: 389.1 },
    { name: "Slovak Republic", value: 140.5 },
    { name: "Slovenia", value: 219.4 },
    { name: "South Africa", value: 890.6 },
    { name: "Spain", value: 150.3 },
    { name: "Sri Lanka", value: 605.7 },
    { name: "South Sudan", value: 841.4 },
    { name: "Sudan", value: 305.7 },
    { name: "Suriname", value: 521.7 },
    { name: "Sweden", value: 14.4 },
    { name: "Switzerland", value: 22.9 },
    { name: "Syrian Arab Republic", value: 669.5 },
    { name: "Chinese Taipei", value: 546 },
    { name: "Tajikistan", value: 71.7 },
    { name: "United Republic of Tanzania", value: 334.9 },
    { name: "Thailand", value: 457.8 },
    { name: "Togo", value: 331.1 },
    { name: "Trinidad and Tobago", value: 526 },
    { name: "Tunisia", value: 423.4 },
    { name: "Republic of TÃ+rkiye", value: 420.1 },
    { name: "Turkmenistan", value: 698.8 },
    { name: "United Arab Emirates", value: 527.8 },
    { name: "Uganda", value: 11.4 },
    { name: "United Kingdom", value: 220 },
    { name: "Ukraine", value: 297.8 },
    { name: "Uruguay", value: 90.6 },
    { name: "United States", value: 366.5 },
    { name: "Uzbekistan", value: 468.6 },
    { name: "Bolivarian Republic of Venezuela", value: 95.8 },
    { name: "Viet Nam", value: 628.4 },
    { name: "Yemen", value: 647.5 },
    { name: "Zambia", value: 88.7 },
    { name: "Zimbabwe", value: 572.7 },
    { name: "Europe", value: 234.6 },
    { name: "Africa", value: 541.7 },
    { name: "Americas", value: 334.8 },
    { name: "Asia Oceania", value: 479.7 },
    { name: "World", value: 459.1 },
  ],
};
