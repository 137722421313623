import React from 'react'

const Requests = props => {
  return (
    <svg
      width={31}
      height={39}
      viewBox="0 0 31 39"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{'container'}</title>
      <path
        d="M29.198.354H1.925C1.171.354.562.96.562 1.71v22.435c0-.025.017-.042.042-.042H9.95l.222 1.047c.516 2.536 2.783 4.381 5.39 4.381 2.609 0 4.876-1.845 5.387-4.38l.222-1.048h9.349c.026 0 .043.017.043.042V1.711c0-.75-.61-1.357-1.364-1.357zm-5.114 18.194c0 .186-.153.34-.34.34H7.38a.341.341 0 01-.341-.34v-2.036c0-.186.153-.34.34-.34h16.364c.188 0 .341.154.341.34v2.036zm0-6.786c0 .187-.153.34-.34.34H7.38a.341.341 0 01-.341-.34V9.726c0-.186.153-.339.34-.339h16.364c.188 0 .341.153.341.34v2.035zm6.435 15.014h-7.197a8.228 8.228 0 01-7.76 5.47c-1.91 0-3.772-.67-5.242-1.878a8.228 8.228 0 01-2.518-3.592H.604c-.025 0-.042-.017-.042-.043v10.264c0 .75.609 1.357 1.363 1.357h27.273c.754 0 1.364-.607 1.364-1.357V26.733c0 .026-.017.043-.043.043z"
        fill={props.fill || '#C5C5C5'}
        fillRule="nonzero"
      />
    </svg>
  )
}
export default Requests
