import React, { useState, useEffect } from "react";
import "./Login.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Input,
  Link,
  useToast,
  CheckboxGroup,
  Checkbox
} from "@chakra-ui/react";
import { setGlobalState } from "../store";
function Login(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [sectors, setSectors] = useState([]);
  const [selectedSectors, setSelectedSectors] = useState([]);
  const toast = useToast();
  setGlobalState("pageClass", "login");

  useEffect(async () => {
    const response = await axios.get(`/sectors`);
    if (response && response.data) {
      setSectors(response.data || []);
    }
  }, []);

  const registerUser = async e => {
    e.preventDefault();
    if (
      lastName === "" ||
      firstName === "" ||
      email === "" ||
      password === ""
    ) {
      return toast({
        title: "Por favor introduza os dados",
        status: "warning",
        position: "bottom-left"
      });
    }

    try {
      const response = await axios.post(`/auth/register`, {
        firstName,
        lastName,
        email,
        password,
        sectors: selectedSectors
      });
      if (response.data) {
        if (response.data && response.data.status === "OK") {
          props.history.push("/");
        } else {
          toast({
            title: response.data.msg
              ? response.data.msg.message
              : "Verifique os seus dados",
            status: "warning",
            position: "bottom-left"
          });
        }
      }
    } catch (e) {
      console.log(e);
      toast({
        title: e || "Verifique os seus dados",
        status: "warning",
        position: "bottom-left"
      });
    }
  };

  return (
    <Flex
      align="center"
      alignItems="center"
      justifyContent={["start", "start", "center", "center", "center"]}
      flexDirection="column"
      height="100vh"
    >
      <Box
        className={"loginwrap"}
        minHeight={["50vh", "50vh", "100vh", "100vh", "100vh"]}
        width={["100%", "100%", "45%", "35%", "35%"]}
        padding={"2em"}
        shadow="md"
        borderWidth="0"
        rounded="lg"
        position={"absolute"}
        bottom={["0", "0", "0", "0", "0"]}
        // background={'url(/images/login.svg)'}
        // backgroundPosition={'right'}
        left="0"
      >
        <div className={"login-container"}>
          <div>
            <Box
              backgroundImage={"url(/images/logo.png)"}
              backgroundSize={"contain"}
              backgroundPosition={"center"}
              backgroundRepeat={"no-repeat"}
              height={["5em", "5em", "6em", "6em", "6em"]}
              margin={["1em 0", "2em 0", "3em 0", "3em 0", "3em 0"]}
            />
            <form
              className="registration-form"
              onSubmit={registerUser}
              data-login-form
            >
              <Flex width={"100%"} justifyContent={"space-between"}>
                <Input
                  width={"47%"}
                  data-login-email
                  onChange={e => setFirstName(e.target.value)}
                  placeholder="First Name"
                  colorScheme="primary"
                  size="lg"
                />
                <Input
                  width={"47%"}
                  data-login-email
                  onChange={e => setLastName(e.target.value)}
                  placeholder="Last Name"
                  colorScheme="primary"
                  size="lg"
                />
              </Flex>
              <Input
                data-login-email
                onChange={e => setEmail(e.target.value)}
                placeholder="Email"
                colorScheme="primary"
                size="lg"
              />
              <Input
                type="password"
                data-login-password
                onChange={e => setPassword(e.target.value)}
                placeholder="Password"
                colorScheme="primary"
                size="lg"
              />

              <CheckboxGroup
                onChange={e => setSelectedSectors(e)}
                colorScheme="primary"
                defaultValue={selectedSectors}
              >
                <Flex
                  width={"100%"}
                  margin={"1em 0"}
                  justifyContent={"space-between"}
                >
                  {sectors.map(sector => {
                    return (
                      <Checkbox value={sector._id}>{sector.name}</Checkbox>
                    );
                  })}
                </Flex>
              </CheckboxGroup>

              <Button
                onClick={e => registerUser(e)}
                colorScheme="secondary"
                size="lg"
                value="Submit!"
                width={"100%"}
                _hover={{
                  background: "primary.500"
                }}
              >
                Register
              </Button>
            </form>
            <Link
              href={"/"}
              fontSize={".8em"}
              margin={"1em"}
              display={"block"}
              color={"white"}
              textAlign={"center"}
              _hover={{
                color: "secondary.500"
              }}
            >
              Login
            </Link>
          </div>
        </div>
      </Box>
      <Box
        width={["100%", "100%", "50%", "40%", "40%"]}
        className={"loginBackground"}
      ></Box>
      <Box
        marginLeft={["0%", "0%", "35%", "35%", "35%"]}
        marginTop={["0", "0", "0%", "0%", "0%"]}
        width={["100%", "100%", "65%", "65%", "65%"]}
        height={["50%", "50%", "100%", "100%", "100%"]}
        backgroundImage={"url(/images/home.jpg)"}
        backgroundSize={"cover"}
        backgroundPosition={[
          "center 50%",
          "center 50%",
          "center",
          "center",
          "center"
        ]}
        backgroundRepeat={"no-repeat"}
      >
        {/* <Heading
          as="h3"
          fontSize={['0.9rem', '1.0rem', '1.2rem', '1.5rem', '1.5rem']}
          color={'white'}
        >
          Assessment application
        </Heading>
        <Heading
          as="h1"
          fontSize={['2.8rem', '4rem', '5rem', '7rem', '7rem']}
          color={'white'}
        >
          Beyond The Obvious
        </Heading> */}
      </Box>
    </Flex>
  );
}

export default withRouter(Login);
