import React, { useState, useEffect } from "react";
import {
  Button,
  Select,
  InputLeftAddon,
  Progress,
  InputGroup,
  Input,
  Box,
  Textarea,
  Flex,
  Icon
} from "@chakra-ui/react";
import moment from "moment";
import { setRemoteCall } from "../remote";
import { Board, BoardImage } from "../components/styled";
import Upload from "../components/Upload";

import { GoTasklist } from "react-icons/go";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton
} from "@chakra-ui/react";
import { getGlobalState } from "../store";
import { RequestName } from "./styled.js";

export default function Edit(props) {
  const loggedIn = getGlobalState("loggedIn");
  const requests = getGlobalState("requests"); //dont remove
  const users = getGlobalState("users");
  const userTypes = getGlobalState("userTypes");
  const [history] = useState(
    props.data && props.data.history
      ? props.data.history
      : props.data !== true
      ? [props.data]
      : null
  );
  const [details, setDetails] = useState({
    status: props.data && props.data.status ? props.data.status : 1,
    date: new Date(),
    user: loggedIn._id,
    parent: props.data ? props.data.parent : null
  });

  const [view] = useState("info");
  const [relations, setRelations] = useState([]);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setRemoteCall("getRelations", props.data, "", cb => {
      if (cb && cb.length > 0) {
        let numberCompleted = 0;
        cb.map(relation => {
          if (relation.status === 0) {
            numberCompleted++;
          }
          return relation;
        });
        setProgress((numberCompleted * 100) / cb.length);
      }
      setRelations(cb);
    });
  }, []);

  const save = () => {
    if (history && history.length > 0) {
      let oldDataHistory = props.data.history;
      if (!oldDataHistory) {
        oldDataHistory = [props.data];
      }
      //lets update history
      const newData = {
        ...props.data,
        status: details.status,
        history: [...[details], ...oldDataHistory]
      };
      props.set(newData, ` updating request`, "requests");
    } else {
      props.set({ ...details }, ` sending request`, "requests");
    }

    props.setOpenModal();
  };
  const handleFormFieldChange = (key, { target: { value } }) => {
    setDetails({ ...details, [key]: value });
  };
  return (
    <Drawer
      isOpen={true}
      placement="right"
      isFullHeight={true}
      size={"md"}
      onClose={() => props.setOpenModal()}
      // finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="0">
          {props.data && props.data.name ? props.data.name : "Edit Request"}
          {relations && relations.length > 0 && (
            <Progress value={progress} borderRadius={"10px"} />
          )}
        </DrawerHeader>

        <DrawerBody overflow={"auto"}>
          {history &&
            history.map(hist => {
              if (!hist) return null;
              let thisUser = null;
              if (users && hist.user) {
                thisUser = users.find(c => c._id === hist.user);
              }
              return (
                <>
                  {hist.file && hist.file.match(/.jpg|.png|.gif/) ? (
                    <BoardImage>
                      <img
                        style={{
                          width: "100%",
                          height: "20vh",
                          objectFit: "cover"
                        }}
                        alt={""}
                        src={hist.file}
                      />
                    </BoardImage>
                  ) : (
                    <Box margin={"2em 0"}>
                      <a href={hist.file} target={"_blank"}>
                        {hist.file}
                      </a>
                    </Box>
                  )}
                  <Board margin={"1em 0"} width={100}>
                    <Flex
                      alignContent={"space-between"}
                      flexDirection={["column", "column", "row", "row", "row"]}
                      width={"100%"}
                    >
                      <Flex width={"70%"}>
                        <Box marginRight={"1em"} mt={".5em"}>
                          {thisUser && thisUser.image ? (
                            <Box
                              borderRadius={"50%"}
                              height={"4em"}
                              width={"4em"}
                              background={`url(${thisUser.image})`}
                              backgroundPosition={"center"}
                              backgroundSize={"cover"}
                              border={".3em solid white"}
                            />
                          ) : (
                            <Icon
                              as={GoTasklist}
                              color={"white"}
                              height={"2em"}
                              width={"2em"}
                            />
                          )}
                        </Box>
                        <RequestName>
                          {hist.details} <br />
                          {thisUser && (
                            <span>
                              {thisUser.firstName || ""}{" "}
                              {thisUser.lastName || ""}
                            </span>
                          )}
                        </RequestName>
                      </Flex>
                      <Box width={"25%"}>
                        <RequestName>
                          <span>
                            {moment(hist.date).format("DD/MM/YYYY HH:mm")}
                          </span>
                          {hist.points && (
                            <>
                              <br />
                              <span>{hist.points} points</span>
                            </>
                          )}
                          <br />
                          {hist.file && (
                            <span>
                              <a href={hist.file} target={"_blank"}>
                                ↓ Open file
                              </a>
                            </span>
                          )}
                        </RequestName>
                      </Box>
                    </Flex>
                  </Board>
                </>
              );
            })}
          <Box mt={"2em"} />
          {props.form &&
            view === "info" &&
            props.form.map((k, i) => {
              if (k.k === "_id" || k.k === "history") return null;
              if (
                (k.k === "user" || k.k === "name" || k.k === "date") &&
                history &&
                history.length > 0
              )
                return null;
              if (
                loggedIn &&
                !loggedIn.admin &&
                (k.k === "date" || k.k === "type" || k.k === "points")
              )
                return null;

              if (
                loggedIn &&
                loggedIn._id !== props.data.owner &&
                k.k === "status"
              )
                return null;

              if (
                (details.type === "news" &&
                  (k.k === "points" || k.k === "parent" || k.k === "user")) ||
                (history &&
                  history.length > 0 &&
                  (k.k === "points" ||
                    k.k === "parent" ||
                    k.k === "user" ||
                    k.k === "type"))
              )
                return null;

              if (k.k === "parent" && props.data._id) return null;

              if (k.k === "role" && props.types)
                return (
                  <InputGroup key={i} margin={"1em 0"} size="sm">
                    <InputLeftAddon
                      minWidth={"120px"}
                      textAlign={"center"}
                      padding={"1.5em .5em"}
                      children={k.l}
                      fontWeight={"bold"}
                    />
                    <Select
                      height="3em"
                      onChange={event => handleFormFieldChange(k.k, event)}
                      placeholder="Select option"
                      value={details[k.k]}
                    >
                      {props.types.map(
                        t =>
                          t.status === 1 && (
                            <option value={t._id}> {t.name}</option>
                          )
                      )}
                    </Select>
                  </InputGroup>
                );
              if (k.k === "type")
                return (
                  <InputGroup key={i} margin={"1em 0"} size="sm">
                    <Select
                      onChange={event => handleFormFieldChange(k.k, event)}
                      placeholder="Choose a type"
                      value={details[k.k]}
                    >
                      <option value={"task"}>Task</option>
                      <option value={"news"}>News</option>
                    </Select>
                  </InputGroup>
                );

              if (k.k === "parent")
                return (
                  <InputGroup key={i} margin={"1em 0"} size="sm">
                    <Select
                      onChange={event => handleFormFieldChange(k.k, event)}
                      placeholder="Choose a parent task"
                      value={details[k.k]}
                    >
                      {requests &&
                        requests.map(request => {
                          if (request.status === 0) return null;
                          return (
                            <option value={request._id}>{request.name}</option>
                          );
                        })}
                    </Select>
                  </InputGroup>
                );
              if (k.k === "user")
                return (
                  <InputGroup key={i} margin={"1em 0"} size="sm">
                    <Select
                      onChange={event => handleFormFieldChange(k.k, event)}
                      placeholder="Who is this request for"
                      value={details[k.k]}
                    >
                      {users &&
                        users.map(us => {
                          let userType;
                          if (userTypes)
                            userType = userTypes.find(c => c._id === us.role);

                          return (
                            <option value={us._id}>
                              {us.firstName || ""} {us.lastName || ""} -{" "}
                              {userType ? userType.name : "global"}
                            </option>
                          );
                        })}
                    </Select>
                  </InputGroup>
                );
              if (k.k === "status")
                return (
                  <InputGroup key={i} margin={"1em 0"} size="sm">
                    <Select
                      onChange={event => handleFormFieldChange(k.k, event)}
                      placeholder="Status"
                      value={details[k.k]}
                    >
                      <option value={0}> Complete </option>
                      <option value={1}> Active </option>
                    </Select>
                  </InputGroup>
                );
              if (k.t === "upload") {
                return (
                  <Box margin={"2em 0"} textAlign={"center"}>
                    {details[k.k] && details[k.k].match(/.jpg|.png|.gif/) ? (
                      <img
                        style={{
                          width: "100%",
                          height: "20vh",
                          objectFit: "contain"
                        }}
                        alt={""}
                        src={details[k.k]}
                      />
                    ) : (
                      <Box margin={"2em 0"}>
                        <a href={details[k.k]} target={"_blank"}>
                          {details[k.k]}
                        </a>
                      </Box>
                    )}
                    <Upload
                      key={i}
                      callback={files =>
                        handleFormFieldChange(k.k, { target: { value: files } })
                      }
                    />
                  </Box>
                );
              }

              if (k.k === "status")
                return (
                  <InputGroup key={i} margin={"1em 0"} size="sm">
                    <Select
                      onChange={event => handleFormFieldChange(k.k, event)}
                      placeholder="Status"
                      value={details[k.k]}
                    >
                      <option value={0}> Complete </option>
                      <option value={1}> Active </option>
                    </Select>
                  </InputGroup>
                );

              if (k.t === "textarea") {
                return (
                  <InputGroup key={i} margin={"1em 0"} size="sm">
                    <Textarea
                      padding={"1.5em .5em"}
                      onChange={event => handleFormFieldChange(k.k, event)}
                      placeholder={k.l}
                      type={k.t}
                      value={details[k.k]}
                    />
                  </InputGroup>
                );
              }
              if (k.t === "date") {
                const thisDate = moment(details[k.k]).format("YYYY-MM-DD");
                return (
                  <Box key={i}>
                    <Input
                      size="lg"
                      onChange={event => handleFormFieldChange(k.k, event)}
                      type={k.t || "text"}
                      placeholder={k.l}
                      value={thisDate}
                    />
                  </Box>
                );
              }
              return (
                <Input
                  size="lg"
                  onChange={event => handleFormFieldChange(k.k, event)}
                  type={k.t || "text"}
                  placeholder={k.l}
                  value={details[k.k]}
                />
              );
            })}
          {relations && relations.length > 0 && (
            <>
              <br />
              Task Hierarchy
              {relations.map(rel => {
                return (
                  <Board width={100}>
                    <a href={`/requests/${rel._id}`}>
                      <Flex
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Flex alignItems={"center"}>
                          <Icon
                            as={GoTasklist}
                            color={"white"}
                            height={"1em"}
                            width={"1em"}
                          />
                          <RequestName>
                            {rel.name}{" "}
                            <span>
                              {moment(rel.date).format("DD/MM/YYYY HH:mm")}
                            </span>
                          </RequestName>
                        </Flex>
                        <Box>{rel.status ? "Active" : "Complete"}</Box>
                      </Flex>
                    </a>
                  </Board>
                );
              })}
            </>
          )}
        </DrawerBody>

        <DrawerFooter borderTopWidth="0">
          {loggedIn && loggedIn.admin ? (
            <Button
              variant="outline"
              colorScheme="primary"
              mr={3}
              onClick={() => {
                props.del(props.data._id);
                props.setOpenModal(false);
              }}
            >
              Delete
            </Button>
          ) : (
            ""
          )}
          <Button
            variant="outline"
            colorScheme="primary"
            mr={3}
            onClick={() => props.setOpenModal()}
          >
            Cancel
          </Button>
          <Button
            colorScheme="secondary"
            onClick={() => {
              save();
            }}
          >
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
