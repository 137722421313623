import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { ListItem } from "@material-ui/core";
import { colors } from "../../theme";
export const ListContainer = styled.ul`
  && {
    min-width: 80px;
    border: none;
  }
`;

export const ItemNumb = styled.span`
  && {
    background: #fff;
    right: 10px;
    font-size: 0.5em !important;
    padding: 0.5em;
    border-radius: 50%;
    position: absolute;
    color: #2d2e3c !important;
    display: block !important;
    min-width: 1.3em;
  }
`;

export const StyledFooter = styled.div`
  && {
    height: 15vh !important;
    li {
      a {
        color: #fff;
      }
    }
    @media screen and (max-width: 768px) {
      position: fixed;
      top: 0.5em;
      height: auto !important;
      right: 2em;
      z-index: 9;
      li {
        margin: 0 0.2em;
        a {
          display: inline-block;
          background: #d8d8d8;
          background-image: linear-gradient(-34deg, #0f1217 0%, #262c3a 100%);
          box-shadow: -5px -5px 15px 0 rgba(255, 255, 255, 0.08),
            8px 8px 16px 0 rgba(0, 0, 0, 0.45),
            inset 1px 1px 0 0 rgba(255, 255, 255, 0.08);
          padding: 5px;
          height: 47px;
          width: 47px;
          border-radius: 50%;
        }
      }
    }
  }
`;

export const StyledDrawer = styled.div`
  && {
    min-width: 80px;
    left: 0;
    top: 0;
    border: none;
    background: ${colors.primary[500]};
    position: fixed;
    z-index: 300;
    height: 100%;
    /* box-shadow: 4px 0 9px 0 rgba(0, 0, 0, 0.06); */
    box-shadow: 0 1px 10px 0 ${colors.primary.transparent[80]};
    & > div {
      border: none;
      width: 80px;
      height: 100%;
    }
    /* & > div > div {
      display: none;
    }
    &:hover > div > div {
      display: block;
    } */

    background: #d8d8d8;
    background-image: linear-gradient(-31deg, #0f1217 0%, #262c3a 100%);
    box-shadow: -8px -10px 35px 0 rgba(255, 255, 255, 0.09),
      10px 10px 30px 0 rgba(0, 0, 0, 0.3),
      inset 1px 1px 0 0 rgba(255, 255, 255, 0.12);

    @media screen and (max-width: 768px) {
      width: 70%;
      height: auto;
      bottom: 1em;
      left: 15%;
      top: auto;
      border-radius: 10px;
      zoom: 0.8;
    }
    @media screen and (max-width: 400px) {
      width: 80%;
      left: 10%;
    }
    @media print {
      display: none;
    }
  }
`;
export const StyledListItem = styled.li`
  && {
    text-align: center;
    display: block;
    position: relative;
    transition: all 0.2s ease-in;
    font-style: italic;
    padding: 1vh 0;
    font-size: 1.4rem;
    text-decoration: none;
    color: ${colors.primary[50]};
    /* border-bottom: 1px solid ${colors.primary[200]}; */
    i{
      position: absolute;
      background: ${colors.primary[200]};
      color:white;
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      right: -6px;
      font-size: .6em;
    }
    &:last-child {
      border-bottom: none;
    }
    svg{
      display:inline-block;
    }
    span {
      color: ${colors.secondary[50]};
      font-size: 0.8rem;
      display: block;
      opacity: 0;
    }

    &:hover {
      text-align: center;
      display: block;
      position: relative;
      text-decoration: none;
      i {
      }
      span {
        color: ${colors.primary[50]};
        opacity: 1;
        display: block;
      }
      @media screen and (max-width: 768px) {
          span {
            display: none;
          }
        }
    }

    ${props =>
      props.active &&
      css`
        text-align: center;
        display: block;
        position: relative;
        text-decoration: none;
          color: white;
        /* color: ${colors.secondary[500]}; */
        i{
          background: ${colors.secondary[500]};
        }
        span {
          color: white;
          opacity: 1;
          display: block;
        }
        @media screen and (max-width: 768px) {
          span {
            display: none;
          }
        }
      `}

    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
      padding: 0;
      margin: 0 0.8em;
      border-bottom: none;
      height: auto;
      span {
        font-size: 0.6rem;
        display:none
      }
      &:focus {
        outline-offset: none;
      }
    }
  }
`;

export const StyledActiveListItem = styled(Link)`
  && {
    text-align: center;
    display: block;
    position: relative;
    border-left: 6px solid #fff;
    text-decoration: none;
    padding: 0.8em 0;
    i {
      color: #fff;
    }
    span {
      color: #fff;
      font-size: 0.7em;
    }
    @media screen and (max-width: 768px) {
      span {
        display: none;
      }
    }
  }
`;

export const MenuIcon = styled.i`
  && {
    width: 100%;
    display: block;
    font-size: 1.3em;
    text-align: center;
    padding: 0.4em 0;
    color: rgba(255, 255, 255, 1);
  }
`;

export const StyledLogo = styled(ListItem)`
  && {
    margin-bottom: 1em;
    padding: 1em;
    text-align: center;
    cursor: pointer;
    height: 15vh;
    width: 80%;
    margin: 0 10%;
    background: url('/images/logoNav.svg');
    background-position: center;
    background-size: contain;
    /* border-bottom: 1px solid ${colors.primary[100]}; */
    background-repeat: no-repeat;
    display: block;
    img {
      /* width: 45%; */
      max-height: 55px;
    }
    ${props => props.url && `background-image: url(${props.url});`}
    @media screen and (max-width: 768px) {
      display:none
    }
  }
`;

export const NumberToApprove = styled.span`
  && {
    background: #fff;
    border-radius: 50%;
    padding: 0.5em;
    font-size: 0.7em;
    position: absolute;
    top: 4px;
    right: -10px;
    font-weight: bold;
  }
`;

export const StyledListWrap = styled.div`
  && {
    display: block;
    height: 70vh;
    width: 100%;
    overflow: auto;
    padding: 0;
    margin: 0;
    -webkit-overflow-scrolling: touch;
    @media screen and (max-width: 768px) {
      height: 70px;
      width: 100%;
    }
  }
`;

export const StyledList = styled.div`
  && {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    &:last-child {
      border-bottom: none;
    }
    @media screen and (max-width: 768px) {
      flex-direction: row;
      height: 70px;
      width: 100%;
    }
  }
`;

export const StyledNavigationPanel = styled.div`
  && {
    border: none;
    z-index: 300;

    div {
      background: ${props => props.background};
    }
  }
`;
