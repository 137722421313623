import { Box, Flex, Heading, Progress } from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaBalanceScaleLeft, FaSmog } from "react-icons/fa";
import { GiPineTree } from "react-icons/gi";
import { ImPowerCord } from "react-icons/im";
import { RiGamepadLine } from "react-icons/ri";
import { MdRecycling } from "react-icons/md";
import { SiGumtree } from "react-icons/si";
import { Link } from "react-router-dom";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import Header from "../components/header";
import { MetricValueWhite } from "../Requests/styled";
import { Board } from "../components/styled";
import { setRemoteCall } from "../remote";
import { CommentNumber, MetricValue, PlayerValue } from "../Requests/styled";
import { getGlobalState, setGlobalState, useGlobalState } from "../store";
import { colors } from "../theme";
import { RotatedCard, RowDashPage, Wrap } from "./styled";

// const MAINCOLORS = [colors.primary[500], colors.primary[200]]
const SUBCOLORS = [
  colors.secondary[500],
  colors.secondary[400],
  colors.secondary[300],
  colors.secondary[200],
  colors.secondary[100],
];

export default function Dashboard(props) {
  setGlobalState("pageClass", "dashboard");
  const users = getGlobalState("users");
  const offsets = getGlobalState("offsets");
  // const loggedIn = getGlobalState('loggedIn') //dont remove
  const [requests] = useGlobalState("requests"); //dont remove

  // const [monthTimeLog, setMonthTimeLog] = useState([])
  const [yearTimeLog, setYearTimeLog] = useState([]);
  const [yearOffset, setYearOffset] = useState([]);
  const [barData, setBarData] = useState([]);

  // const [radarData, setRadarData] = useState(null)

  const [printing] = useState(false);

  // const getMonthData = () => {
  //   setRemoteCall('getMonthTimeLog', {}, '', cb => {
  //     setMonthTimeLog(cb)
  //   })
  // }
  const getYearStats = () => {
    setRemoteCall("getTeamStats", {}, "", (cb) => {
      setYearTimeLog(cb);
    });
    setRemoteCall("getCurrentYearOffsets", {}, "", (cb) => {
      setYearOffset(cb);
    });
  };

  useEffect(() => {
    workBarData(yearTimeLog);
  }, [yearTimeLog]);
  // useEffect(() => {
  //   workRadarData(monthTimeLog)
  // }, [monthTimeLog])

  useEffect(() => {
    // getMonthData()
    getYearStats();
  }, []);

  const getLevelName = () => {
    if (
      yearTimeLog &&
      yearTimeLog.totalPointsAveragePerUser &&
      yearTimeLog.totalPointsAveragePerUser > 0
    ) {
      let levelName = "Newbie";

      if (yearTimeLog.totalPointsAveragePerUser >= 150) {
        levelName = "World Class";
      } else if (yearTimeLog.totalPointsAveragePerUser >= 100) {
        levelName = "Professional";
      } else if (yearTimeLog.totalPointsAveragePerUser >= 50) {
        levelName = "Amateur";
      } else if (yearTimeLog.totalPointsAveragePerUser >= 0) {
        levelName = "Newbie";
      }
      return levelName;
    }
    return "Newbie";
  };

  const getNexLevelPoints = () => {
    if (
      yearTimeLog &&
      yearTimeLog.totalPointsAveragePerUser &&
      yearTimeLog.totalPointsAveragePerUser > 0
    ) {
      let nextLevelPoint = 50;

      if (yearTimeLog.totalPointsAveragePerUser >= 150) {
        nextLevelPoint = 200 - yearTimeLog.totalPointsAveragePerUser;
      } else if (yearTimeLog.totalPointsAveragePerUser >= 100) {
        nextLevelPoint = 150 - yearTimeLog.totalPointsAveragePerUser;
      } else if (yearTimeLog.totalPointsAveragePerUser >= 50) {
        nextLevelPoint = 100 - yearTimeLog.totalPointsAveragePerUser;
      } else if (yearTimeLog.totalPointsAveragePerUser >= 0) {
        nextLevelPoint = 50 - yearTimeLog.totalPointsAveragePerUser;
      }
      return nextLevelPoint;
    }
    return 0;
  };

  const getCurrentLevelProgress = () => {
    if (
      yearTimeLog &&
      yearTimeLog.totalPointsAveragePerUser &&
      yearTimeLog.totalPointsAveragePerUser > 0
    ) {
      let totalRankPoints = 0;
      if (yearTimeLog.totalPointsAveragePerUser >= 150) {
        totalRankPoints = 200;
      }
      if (yearTimeLog.totalPointsAveragePerUser >= 100) {
        totalRankPoints = 150;
      } else if (yearTimeLog.totalPointsAveragePerUser >= 50) {
        totalRankPoints = 100;
      }
      return (totalRankPoints * 100) / 50;
    }
    return 0;
  };

  const workBarData = (data, i) => {
    const { outputDataPerMonth } = data;
    if (outputDataPerMonth && Object.keys(outputDataPerMonth).length > 0) {
      let dataInit = [];
      // const parent = Object.keys(data.mainCat)[i || 0]
      Object.keys(outputDataPerMonth).map((month) => {
        const thisInit = outputDataPerMonth[month];
        console.log(thisInit);
        if (thisInit)
          dataInit.push({
            subject: thisInit.date,
            // subject: moment(Number(month) + 1, "M"),
            // A: Math.floor(Math.random() * 100) + 1,
            "Kw/h": Number(thisInit.power).toFixed(2),
            co2: Number(thisInit.co2).toFixed(2),
            trees: Number(thisInit.trees).toFixed(2),
            hours: Number(thisInit.hours).toFixed(2),
            planned: Number(thisInit.planned).toFixed(2),
          });
        return month;
      });
      setBarData(dataInit.sort((a, b) => a.subject - b.subject));
    }
  };

  const RenderStats = () => {
    return (
      <Flex
        width={"100%"}
        justifyContent={"space-around"}
        flexDirection={["column", "column", "row", "row", "row"]}
        flexWrap={"wrap"}
      >
        <Box width={{ base: "100%", md: "18%" }}>
          <Board margin={"0 0 1em 0"} width={"100%"}>
            <Flex alignItems={"center"} width={"100%"}>
              <RiGamepadLine size={45} />
              <Box ml={".5em"} fontSize={"1.1em"} width={"100%"}>
                <MetricValue>
                  {getLevelName()}
                  <span>
                    {(yearTimeLog?.totalPointsAveragePerUser || 0).toFixed(1)}{" "}
                    team pts
                  </span>

                  <Box width={"100%"}>
                    <Progress
                      mt={"0.2em"}
                      value={getCurrentLevelProgress()}
                      borderRadius={"1em"}
                      colorScheme={"red"}
                    />
                  </Box>
                </MetricValue>
              </Box>
            </Flex>
          </Board>
        </Box>
        <Box width={{ base: "100%", md: "18%" }}>
          <Board active={true} margin={"0 0 1em 0"} width={"100%"}>
            <Flex alignItems={"center"}>
              <ImPowerCord size={45} />
              <Box ml={".5em"} fontSize={"1.4em"}>
                <MetricValue>
                  {yearTimeLog && yearTimeLog?.total?.power.toFixed(1)}
                  <span>Kw/h</span>
                </MetricValue>
              </Box>
            </Flex>
          </Board>
        </Box>
        <Box width={{ base: "100%", md: "18%" }}>
          <Board dark={true} active={true} margin={"0 0 1em 0"} width={"100%"}>
            <Flex alignItems={"center"}>
              <FaSmog size={45} />
              <Box ml={".5em"} fontSize={"1.4em"}>
                <MetricValue>
                  {yearTimeLog && yearTimeLog?.total?.co2.toFixed(2)}
                  <span>Kg CO2</span>
                </MetricValue>
              </Box>
            </Flex>
          </Board>
        </Box>
        <Box width={{ base: "100%", md: "18%" }}>
          <Board dark={true} active={true} margin={"0 0 1em 0"} width={"100%"}>
            <Flex alignItems={"center"}>
              <SiGumtree size={45} />
              <Box ml={".5em"} fontSize={"1.4em"}>
                <MetricValue>
                  {yearTimeLog && yearTimeLog?.total?.trees.toFixed(2)}{" "}
                  <span>Trees</span>
                </MetricValue>
              </Box>
            </Flex>
          </Board>
        </Box>{" "}
        <Box width={{ base: "100%", md: "18%" }}>
          <Board active={true} margin={"0 0 1em 0"} width={"100%"}>
            <Flex alignItems={"center"}>
              <MdRecycling size={45} color="white" />
              <Box ml={".5em"} fontSize={"1.4em"}>
                <MetricValueWhite>
                  {offsets
                    ? offsets
                        .reduce((acc, curr) => {
                          if (curr?.value) {
                            return acc + Number(curr.value || 0) / 1000;
                          }
                          return acc;
                        }, 0)
                        .toFixed(1)
                    : 0}
                  <span>Ton CO2</span>
                </MetricValueWhite>
              </Box>
            </Flex>
          </Board>
        </Box>
        {/* <Box width={["100%"]}>
          <Board active={true} margin={"0 0 1em 0"} width={"100%"}>
            <Flex alignItems={"center"}>
              <RiGamepadLine size={45} />
              <Box ml={".5em"} fontSize={"1.4em"}>
                <MetricValue>
                  {yearTimeLog && yearTimeLog?.total?.hours.toFixed(2)}
                  <span>Hours</span>
                </MetricValue>
              </Box>
            </Flex>
          </Board>
        </Box> */}
      </Flex>
    );
  };
  const RenderMonthData = () => {
    return (
      <>
        <Flex
          alignContent={"space-between"}
          width={"100%"}
          flexDirection={["column", "column", "row", "row", "row"]}
        >
          <Box height={"40vh"} width={"100%"}>
            <Flex justifyContent={["center", "center", "left", "left", "left"]}>
              <Heading
                size={"lg"}
                mb={".0"}
                color={["white", "white", "white"]}
                textAlign={["center", "center", "left", "left", "left"]}
              >
                Team produced{" "}
                {yearTimeLog &&
                  yearTimeLog.total &&
                  yearTimeLog.total.co2 &&
                  yearTimeLog.total.co2.toFixed(2)}
                {"Kg "}
                co2
              </Heading>
              <Box ml={".3em"} color={["white", "white", "white"]}>
                <FaBalanceScaleLeft size={35} />
              </Box>
            </Flex>
            <Flex justifyContent={["center", "center", "left", "left", "left"]}>
              <Heading
                size={"md"}
                mb={".0"}
                textAlign={["center", "center", "left", "left", "left"]}
              >
                to offset you will need to plant{" "}
                {yearTimeLog &&
                  yearTimeLog.total &&
                  yearTimeLog.total.trees &&
                  yearTimeLog.total.trees.toFixed(2)}
              </Heading>
              <Box>
                <GiPineTree size={25} />
              </Box>
            </Flex>

            <ResponsiveContainer width="100%" height="80%">
              <BarChart width={730} height={250} data={barData}>
                <XAxis dataKey="subject" />
                {/* <YAxis /> */}
                <Tooltip cursor={{ opacity: 0.1 }} />
                {/* <Legend /> */}
                <Bar dataKey="Kw/h" stackId="a" fill={SUBCOLORS[0]} />
                <Bar dataKey="co2" stackId="a" fill={SUBCOLORS[1]} />
                <Bar dataKey="trees" stackId="a" fill={SUBCOLORS[2]} />
                <Bar dataKey="hours" stackId="a" fill={SUBCOLORS[3]} />
                <Bar dataKey="planned" stackId="a" fill={SUBCOLORS[4]} />
                {/* <Bar dataKey="B" fill="#82ca9d" /> */}
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Flex>
      </>
    );
  };

  const RenderPieOffset = () => {
    const totalConsumed = yearTimeLog?.total?.co2 || 0;
    const totalOffset = offsets?.reduce((acc, curr) => {
      if (curr?.value) {
        return acc + Number(curr.value || 0);
      }
      return acc;
    }, 0);

    //get percentage of offset from total consumed co2
    let percentage = (totalOffset / totalConsumed) * 100;
    if (percentage > 100) {
      percentage = 100;
    } else if (!percentage || percentage < 0) {
      percentage = 0;
    }
    return (
      <RotatedCard
        padding="0"
        background={`linear-gradient(180deg, rgba(27, 124, 95, 0.8) 0%, rgba(27, 124, 95, 0.8) ${
          percentage > 90 ? percentage : percentage - 20
        }%, rgba(201, 55, 0, 0.8) ${percentage}%)`}
        border={"5px solid #353f55"}
      >
        <Flex
          width={"100%"}
          height={{ base: "30vh", sm: "140px", md: "40vh" }}
          minH={{ md: "340px" }}
          fontSize={{ base: ".8em", sm: "1em", md: "1em" }}
          flexDirection={{ base: "column", sm: "row", md: "column" }}
        >
          <Flex
            width={"100%"}
            height={{ base: "100%", md: "40%" }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Flex alignItems={"center"} flexDir={"column"}>
              <MdRecycling size={70} color={"white"} />
              <Box ml={".5em"} fontSize={"1.4em"}>
                <MetricValueWhite>
                  {offsets
                    ? offsets
                        .reduce((acc, curr) => {
                          if (curr?.value) {
                            return acc + Number(curr.value || 0) / 1000;
                          }
                          return acc;
                        }, 0)
                        .toFixed(1)
                    : 0}
                  <span>Ton CO2</span>
                </MetricValueWhite>
              </Box>
            </Flex>
          </Flex>
          <Flex
            width={"100%"}
            height={{ base: "100%", md: "20%" }}
            alignItems={"center"}
            justifyContent={"center"}
            position={"relative"}
            background={"#353f55"}
            fontSize={{ base: ".8em", lg: "1em" }}
          >
            <MetricValue>
              <Box opacity={".5"}>Offset</Box>{" "}
              <Box fontSize={"2.4em"}>{percentage?.toFixed(1)}%</Box>
            </MetricValue>
          </Flex>
          <Flex
            width={"100%"}
            height={{ base: "100%", md: "40%" }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Box>
              <Flex alignItems={"center"} flexDir={"column"}>
                {" "}
                <FaSmog size={70} color={"white"} />
                <Box ml={".5em"} fontSize={"1.4em"}>
                  <MetricValueWhite>
                    {yearTimeLog &&
                      ((yearTimeLog?.total?.co2 || 0) / 1000).toFixed(1)}
                    <span>Ton CO2</span>
                  </MetricValueWhite>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </RotatedCard>
    );
  };

  const ref = React.useRef(null);

  return (
    <Wrap className={`dashboard ${printing ? "printing" : ""}`} ref={ref}>
      <Header image={"/images/background.jpg"} height={90} mheight={70}>
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          flexDirection={["column", "column", "row", "row", "row"]}
        >
          <Box
            width={["100%", "100%", "60%", "60%", "60%"]}
            padding={["2em 0", "2em 0", "0", "0", "0"]}
            height={"40vh"}
            marginBottom={"2em"}
          >
            <RenderMonthData />
          </Box>
          <Box
            display={["none", "none", "block", "block", "block"]}
            width={["100%", "100%", "20%", "20%", "20%"]}
            ml={["0", "0", "5%", "5%", "5%"]}
          >
            <RenderPieOffset />
          </Box>
        </Flex>
      </Header>
      <Box className={"wrapper"} mb={"10vh"}>
        {" "}
        <Box
          width={"100%"}
          display={["block", "block"]}
          paddingBottom={["5vh", "5vh", "0%", "0%", "0%"]}
        >
          <RenderStats />
        </Box>{" "}
        <Box
          width={"100%"}
          display={["block", "block", "none", "none", "none"]}
          paddingBottom={["15vh", "15vh", "0%", "0%", "0%"]}
        >
          <RenderPieOffset />
        </Box>
        <RowDashPage>
          <Flex width={"100%"} justifyContent={"flex-start"} flexWrap={"wrap"}>
            {requests &&
              requests.map((request) => {
                let thisUser;
                if (users) {
                  thisUser = users.find((c) => c._id === request.owner);
                }
                const commentNumber =
                  request && request.history ? request.history.length : 0;
                return (
                  <Board margin={"0 3% 3% 0"}>
                    <Link to={`/requests/${request._id}`}>
                      {request && (request.image || request.file) && (
                        <Box
                          height={"15vh"}
                          margin={"-1em -1em 1em -1em"}
                          background={`url(${request.image || request.file})`}
                          backgroundPosition={"center"}
                          backgroundSize={"cover"}
                          border={".3em solid white"}
                          borderRadius={"1em 1em 0 0"}
                        />
                      )}
                      <Flex
                        justifyContent={"space-between"}
                        width={"100%"}
                        alignItems={"center"}
                      >
                        <Flex
                          alignContent={"center"}
                          className={"content"}
                          alignItems={"center"}
                        >
                          <Box marginRight={"1em"} width={"40px"}>
                            {request.image ? (
                              <Box
                                borderRadius={"50%"}
                                height={"4em"}
                                width={"4em"}
                                background={`url(${request.image})`}
                                backgroundPosition={"center"}
                                backgroundSize={"cover"}
                                border={".3em solid white"}
                              />
                            ) : (
                              <CommentNumber>
                                {commentNumber || 1}
                              </CommentNumber>
                              // <Icon
                              //   as={GoTasklist}
                              //   color={'white'}
                              //   height={'2em'}
                              //   width={'2em'}
                              // />
                            )}
                          </Box>
                          <Box>
                            <PlayerValue>
                              {request.name}
                              <span>
                                {request.points ? `${request.points} pts` : ""}
                                {" / "}
                                {moment(request.date).format(
                                  "DD/MM/YYYY HH:mm"
                                )}
                              </span>
                              <span>
                                {thisUser
                                  ? `${thisUser.firstName} ${thisUser.lastName}`
                                  : ""}{" "}
                              </span>
                            </PlayerValue>
                          </Box>
                        </Flex>
                        {thisUser && thisUser.image && (
                          <Box
                            borderRadius={"50%"}
                            height={"4.5em"}
                            width={"4.5em"}
                            background={`url(${thisUser.image})`}
                            backgroundPosition={"center"}
                            backgroundSize={"cover"}
                            border={".3em solid white"}
                          />
                        )}
                      </Flex>
                    </Link>
                  </Board>
                );
              })}
          </Flex>
        </RowDashPage>
      </Box>
    </Wrap>
  );
}
