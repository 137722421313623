import styled, { css } from "styled-components";
import { colors } from "../theme";

export const BoardImage = styled.div`
  && {
    box-shadow: -10px -10px 35px 0 rgba(255, 255, 255, 0.04),
      15px 15px 40px 0 rgba(0, 0, 0, 0.35),
      inset 1px 1px 0 0 rgba(255, 255, 255, 0.07);
    transform: skew(0, -2deg) !important;
    border-radius: 20px;
    margin-bottom: -2em;
    img {
      border-radius: 20px;
    }
    > div {
      transform: skew(0, 2deg) !important;
    }
  }
`;

export const Board = styled.div`
  && {
    width: ${props => (props.width ? `${props.width}%` : `30%`)};
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 1em;
    margin: ${props => (props.margin ? `${props.margin}` : `1em 0`)};
    box-shadow: 5px 10px 10px 0 ${colors.primary.transparent[20]};

    background: #d8d8d8;
    max-width: ${props => (props.width ? `${props.width}%` : `30%`)};
    @media screen and (max-width: 768px) {
      width: 100%;
      max-width: 100%;
      margin: ${props => (props.mMargin ? `${props.mMargin}` : `0.5em 0`)};
    }
    ${props =>
      props.percentage
        ? css`
            background-image: linear-gradient(
                120deg,
                ${colors.secondary.transparent[40]} 0%,
                ${colors.secondary.transparent[40]} ${props.percentage}%,
                rgba(0, 21, 25, 0) ${props.percentage}%
              ),
              linear-gradient(-32deg, #191e26 0%, #353f55 100%);
          `
        : css`
            background-image: linear-gradient(-32deg, #191e26 0%, #353f55 100%);
          `};
    ${props =>
      props.active
        ? css`
            background: #d8d8d8;
            background-image: linear-gradient(-29deg, #8a000c 0%, #e10014 100%);
            box-shadow: 8px 10px 18px 0 rgba(0, 0, 0, 0.3),
              inset 1px 1px 1px 0 rgba(255, 255, 255, 0.2);
          `
        : css``}
    ${props =>
      props.dark
        ? css`
            background: #44080e;
            background-image: linear-gradient(-29deg, #44080e 0%, #90000d 100%);
            box-shadow: 8px 10px 18px 0 rgba(0, 0, 0, 0.3),
              inset 1px 1px 1px 0 rgba(255, 255, 255, 0.2);
          `
        : css``}
    box-shadow: -10px -10px 35px 0 rgba(255, 255, 255, 0.04),
      15px 15px 40px 0 rgba(0, 0, 0, 0.35),
      inset 1px 1px 0 0 rgba(255, 255, 255, 0.07);
    border-radius: 30px;
    /* clip-path: path('http://localhost:3001/images/boardMask.svg#boardMask'); */

    /* clip-path: path(
      'M23.1722138,43.652575 L614.172214,0.327761853 C627.942382,-0.681696602 639.923636,9.66290224 640.933094,23.4330701 C640.977683,24.0413121 641,24.6509821 641,25.2608563 L641,277.871552 C641,291.67867 629.807119,302.871552 616,302.871552 L25,302.871552 C11.1928813,302.871552 -2.91021396e-11,291.67867 -2.91038305e-11,277.871552 L-2.91038305e-11,68.5856694 C-2.91018818e-11,55.4876885 10.109286,44.6101873 23.1722138,43.652575 Z'
    ); */
    /* clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%); */
    /* background: url('images/boardMaks.svg') no-repeat; */
    transform: skew(0, -2deg) !important;
    border-radius: 20px;
    > div {
      /* transform: skew(0, 2deg) !important; */
    }
  }
`;

export const BoardWhite = styled.div`
  && {
    width: ${props => (props.width ? `${props.width}%` : `30%`)};
    border: none;
    color: ${colors.primary[500]};
    cursor: pointer;
    padding: 1em;
    margin: 0 0 1em 0;
    box-shadow: 5px 10px 10px 0 ${colors.primary.transparent[20]};
    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 0.5em 0;
    }

    background: #d8d8d8;
    ${props =>
      props.percentage
        ? css`
            background-image: linear-gradient(
                120deg,
                ${colors.secondary.transparent[40]} 0%,
                ${colors.secondary.transparent[40]} ${props.percentage}%,
                rgba(0, 21, 25, 0) ${props.percentage}%
              ),
              linear-gradient(-32deg, #191e26 0%, #353f55 100%);
          `
        : css`
            background-image: linear-gradient(-32deg, #191e26 0%, #353f55 100%);
          `};
    ${props =>
      !props.active
        ? css`
            background: #d8d8d8;
            background-image: linear-gradient(-29deg, #8a000c 0%, #e10014 100%);
            box-shadow: 8px 10px 18px 0 rgba(0, 0, 0, 0.3),
              inset 1px 1px 1px 0 rgba(255, 255, 255, 0.2);
          `
        : css``}
    background: #D8D8D8;
    background-image: linear-gradient(-36deg, #6f757f 0%, #ffffff 100%);
    box-shadow: -10px -10px 15px 0 rgba(255, 255, 255, 0.05),
      12px 12px 45px 0 rgba(0, 0, 0, 0.55),
      inset 6px 0 15px 0 rgba(73, 95, 240, 0.18),
      inset 1px 1px 0 0 rgba(255, 255, 255, 0.09);
    border-radius: 17px;
    box-shadow: -10px -10px 35px 0 rgba(255, 255, 255, 0.04),
      15px 15px 40px 0 rgba(0, 0, 0, 0.35),
      inset 1px 1px 0 0 rgba(255, 255, 255, 0.07);
    border-radius: 30px;
    /* clip-path: path('http://localhost:3001/images/boardMask.svg#boardMask'); */

    /* clip-path: path(
      'M23.1722138,43.652575 L614.172214,0.327761853 C627.942382,-0.681696602 639.923636,9.66290224 640.933094,23.4330701 C640.977683,24.0413121 641,24.6509821 641,25.2608563 L641,277.871552 C641,291.67867 629.807119,302.871552 616,302.871552 L25,302.871552 C11.1928813,302.871552 -2.91021396e-11,291.67867 -2.91038305e-11,277.871552 L-2.91038305e-11,68.5856694 C-2.91018818e-11,55.4876885 10.109286,44.6101873 23.1722138,43.652575 Z'
    ); */
    /* clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%); */
    /* background: url('images/boardMaks.svg') no-repeat; */
    transform: skew(0, -2deg) !important;
    border-radius: 20px;
    > div {
      transform: skew(0, 2deg) !important;
    }
  }
`;
