import React, { useState } from 'react'
import { Button, Box, Flex, useToast } from '@chakra-ui/react'
import { getGlobalState } from '../../store'
import { Board } from '../../components/styled'
import { DeviceName } from '../styled.js'
import RenderEditDevice from './EditDevice'

import Tv from '../icons/Tv'
import Mouse from '../icons/Mouse'
import Joypad from '../icons/Joypad'
import Edit from '../../icons/Edit'

export default function Devices(props) {
  const toast = useToast()

  const loggedIn = getGlobalState('loggedIn')
  const [formEdit, setFormEdit] = useState(null)
  const [details, setDetails] = useState({
    deviceArray: props.data || [],
    name: '',
    isDefault: false,
    power: '',
    type: '',
    id: null
  })

  const handleFormFieldChange = (key, { target: { value } }) => {
    setDetails({ ...details, [key]: value })
  }

  const addNewDevice = () => {
    setDetails({
      ...details,
      isDefault: false,
      name: '',
      power: '',
      type: '',
      id: null
    })
    setFormEdit(!formEdit)
  }
  const editDevice = i => {
    setDetails({
      ...details,
      isDefault: false,
      ...details.deviceArray[i],
      id: i
    })
    setFormEdit(true)
    // toast({
    //   title: 'Device edited',
    //   description: 'Remember to save you profile',
    //   status: 'warning',
    //   position: 'bottom'
    // })
  }
  const removeDevice = i => {
    details.deviceArray.splice(i, 1)
    setDetails({ ...details, deviceArray: details.deviceArray, id: null })
    props.setData(details.deviceArray)
    setFormEdit(false)
    toast({
      title: 'Device removed',
      description: 'Remember to save you profile',
      status: 'warning',
      position: 'bottom'
    })
  }
  const addDevice = i => {
    const { id, name, power, type, isDefault } = details
    if (!type) return null
    let newDevice = [...details.deviceArray, { name, power, type, isDefault }]

    if (id || id === 0) {
      newDevice = details.deviceArray.map((d, i) => {
        if (details.id === i) {
          return { name, power, type, isDefault }
        }
        return d
      })
    }
    setDetails({ ...details, deviceArray: newDevice, id: null })
    props.setData(newDevice)
    setFormEdit(false)
    toast({
      title: 'Device added',
      description: 'Remember to save you profile',
      status: 'warning',
      position: 'bottom'
    })
  }

  return (
    <>
      {((loggedIn && loggedIn.admin) || props.userId === loggedIn._id) && (
        <Flex justifyContent={'flex-end'} paddingBottom={'1em'}>
          <Button size={'sm'} height={'2em'} onClick={() => addNewDevice()}>
            {formEdit ? `X close` : `+ add new`}
          </Button>
        </Flex>
      )}
      {/* <InputGroup margin={'1em 0'} size="sm">
        <Input
          padding={'.5em .5em'}
          height={'3em'}
          onChange={event => handleFormFieldChange('dateStart', event)}
          type={'datetime-local'}
          value={details.dateStart}
        />
        <Input
          padding={'.5em .5em'}
          height={'3em'}
          onChange={event => handleFormFieldChange('dateEnd', event)}
          type={'datetime-local'}
          value={details.dateEnd}
        />
      </InputGroup> */}
      {formEdit && !details.id && details.id !== 0 && (
        <RenderEditDevice
          handleFormFieldChange={handleFormFieldChange}
          details={details}
          addDevice={addDevice}
        />
      )}
      {details.deviceArray &&
        details.deviceArray.map((device, i) => (
          <Box key={i}>
            {details.id !== i && (
              <Board width={100}>
                <Flex
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  onClick={() => {
                    if (
                      (loggedIn && loggedIn.admin) ||
                      props.userId === loggedIn._id
                    ) {
                      editDevice(i)
                    }
                  }}
                >
                  <Flex alignItems={'center'}>
                    <div>
                      {device.type === 'console' && (
                        <Joypad fill={'#fff'} height={30} />
                      )}
                      {device.type === 'tv' && (
                        <Tv height={30} width={50} fill={'#fff'} />
                      )}
                      {device.type === 'computer' && (
                        <Mouse fill={'#fff'} height={40} />
                      )}
                    </div>
                    <DeviceName>
                      {device.name} - {device.power} Kw/h
                      <span>
                        {device.isDefault ? ' ( Default device ) ' : ''}
                      </span>
                    </DeviceName>
                  </Flex>
                  {((loggedIn && loggedIn.admin) ||
                    props.userId === loggedIn._id) && (
                    <Flex justifyContent={'flex-end'} paddingBottom={'1em'}>
                      <Edit width={20} />
                    </Flex>
                  )}
                </Flex>
              </Board>
            )}
            {formEdit && details.id === i && (
              <RenderEditDevice
                i={i}
                handleFormFieldChange={handleFormFieldChange}
                details={details}
                removeDevice={removeDevice}
                addDevice={addDevice}
              />
            )}
          </Box>
        ))}
    </>
  )
}
