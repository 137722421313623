import React, { useState, useEffect } from "react";
import moment from "moment";
import { useStateWithCallbackLazy } from "use-state-with-callback";

import { InputGroup, Input, Button, Box, Flex } from "@chakra-ui/react";
import { setRemoteCall } from "../../remote";
import { getGlobalState } from "../../store";
import { Board } from "../../components/styled";
import { DeviceName } from "../styled.js";
import RenderEditTimelog from "./EditTimelog";
import { AiOutlineClockCircle, AiFillClockCircle } from "react-icons/ai";

import Tv from "../icons/Tv";
import Mouse from "../icons/Mouse";
import Joypad from "../icons/Joypad";
import Edit from "../../icons/Edit";

export default function RenderHistory(props) {
  const loggedIn = getGlobalState("loggedIn");
  const [formEdit, setFormEdit] = useState(null);
  const [timelog, setTimelog] = useState(null);
  const [search, setSearch] = useStateWithCallbackLazy({
    start: moment().startOf("month"),
    end: moment(),
    user: props.userId
  });
  const [details, setDetails] = useState({
    start: new Date(),
    end: new Date(),
    devices: "",
    task: "timelog",
    user: props.userId || loggedIn._id,
    status: 0,
    id: null
  });

  const getPlayerTimeLog = newSearch => {
    setRemoteCall("getMonthTimeLog", newSearch || search, "", cb => {
      setTimelog(cb);
    });
  };

  useEffect(() => {
    getPlayerTimeLog();
  }, []);

  const handleFormFieldChange = (key, { target: { value } }) => {
    setDetails({ ...details, [key]: value });
  };

  const addNewLog = () => {
    setDetails({
      ...details,
      start: new Date(),
      end: new Date(),
      user: props.userId || loggedIn._id,
      devices: "",
      task: "",
      status: 0,
      id: null
    });
    setFormEdit(!formEdit);
  };
  const editLog = i => {
    setDetails({ ...timelog[i], id: i });
    setFormEdit(true);
  };
  const removeLog = i => {
    // details.deviceArray.splice(i, 1)
    // setDetails({ ...details, id: null })
    // delRemoteLog(details.deviceArray)
    // setFormEdit(false)
  };
  const setLog = i => {
    const { start, end, devices } = details;
    if ((!start && !end) || !devices) return null;
    setDetails({ ...details, id: null });

    setRemoteCall("setTimeLog", details, null, data => {
      getPlayerTimeLog();
      // setTimelog(timelog.map((l, a) => (i === a ? details : l))) // update current state
      setDetails({
        ...details,
        start: new Date(),
        end: new Date(),
        user: props.userId || loggedIn._id,
        devices: "",
        task: "",
        details: "",
        status: 0,
        id: null
      });
    });

    setFormEdit(false);
  };

  return (
    <>
      {((loggedIn && loggedIn.admin) || props.userId === loggedIn._id) &&
        !formEdit && (
          <Flex justifyContent={"flex-end"} paddingBottom={"1em"}>
            <Button size={"sm"} height={"2em"} onClick={() => addNewLog()}>
              {`+ add new`}
            </Button>
          </Flex>
        )}
      <InputGroup margin={"0 0 1em 0"} borderRadius={"8px"} size="sm">
        <Input
          padding={".5em .5em"}
          height={"3em"}
          onChange={event => {
            setSearch(
              { ...search, start: moment(event.target.value) },
              newSearch => getPlayerTimeLog(newSearch)
            );
          }}
          type={"datetime-local"}
          value={moment(search.start).format("YYYY-MM-DDTHH:mm")}
        />
        <Input
          padding={".5em .5em"}
          height={"3em"}
          onChange={event => {
            setSearch(
              { ...search, end: moment(event.target.value) },
              newSearch => getPlayerTimeLog(newSearch)
            );
          }}
          type={"datetime-local"}
          value={moment(search.end).format("YYYY-MM-DDTHH:mm")}
        />
      </InputGroup>
      {formEdit && !details.id && details.id !== 0 && (
        <>
          <Flex justifyContent={"flex-end"} paddingBottom={"1em"}>
            <Button size={"sm"} height={"2em"} onClick={() => addNewLog()}>
              {`X close`}
            </Button>
          </Flex>
          <RenderEditTimelog
            handleFormFieldChange={handleFormFieldChange}
            details={details}
            devices={props.devices}
            setLog={setLog}
          />
        </>
      )}
      {timelog &&
        timelog.map((log, i) => {
          // get dif in hours
          const start = moment(log.start);
          let end = moment();
          if (log.end) {
            end = moment(log.end);
          }
          const durationLogFull = moment.duration(end.diff(start));
          const secondFull = durationLogFull.asHours();
          let showed = [];
          let powerUsed = 0;

          if (log.devices && log.devices.length > 0) {
            log.devices.map(device => {
              const durationLogFull = moment.duration(end.diff(start));
              const secondFull = durationLogFull.asHours();
              powerUsed +=
                (Number(secondFull) || 0) * (Number(device.power) || 0);
              return device;
            });
          }
          return (
            <Box key={i}>
              {details.id !== i && (
                <Board width={100}>
                  <Flex
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    onClick={() => {
                      if (
                        (loggedIn && loggedIn.admin) ||
                        props.userId === loggedIn._id
                      ) {
                        editLog(i);
                      }
                    }}
                    width={"100%"}
                  >
                    <Flex
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      width={"100%"}
                      flexDirection={["column", "column", "row", "row", "row"]}
                    >
                      <DeviceName>
                        <Flex alignItems={"center"}>
                          <Box
                            mr={".4em"}
                            lineHeight={".8"}
                            textAlign={"center"}
                          >
                            <Box fontSize={["1.6em"]} mt={".4em"}>
                              {secondFull.toFixed(2)}h
                            </Box>

                            <Box fontSize={[".8em"]} opacity={".6"} mt={".1em"}>
                              {powerUsed.toFixed(2)}Kw
                            </Box>
                          </Box>
                          <Box>
                            <Box
                              fontSize={".6em"}
                              color={"white"}
                              mb={".4em"}
                              textAlign={"left"}
                            >
                              {log.task}{" "}
                              {log.attr && log.attr.planned
                                ? `( ${log.attr.planned}min )`
                                : ""}
                            </Box>
                            <Flex>
                              <Box mr={".3em"}>
                                <AiFillClockCircle size={15} />
                              </Box>
                              <span>
                                {moment(log.end || new Date()).format(
                                  "DD-MM-YYYY HH:mm:ss"
                                )}
                              </span>
                            </Flex>
                            <Flex>
                              <Box mr={".3em"}>
                                <AiOutlineClockCircle size={15} />
                              </Box>
                              <span>
                                {moment(log.start).format(
                                  "DD-MM-YYYY HH:mm:ss"
                                )}
                              </span>
                            </Flex>
                          </Box>
                        </Flex>
                      </DeviceName>

                      <Flex display={["none", "none", "flex", "flex", "flex"]}>
                        {log.devices &&
                          log.devices.map((device, a) => {
                            if (!showed[device.type]) {
                              showed[device.type] = true;
                              return (
                                <div key={a}>
                                  {device.type === "console" && (
                                    <Joypad fill={"#fff"} height={20} />
                                  )}
                                  {device.type === "tv" && (
                                    <Tv height={20} width={50} fill={"#fff"} />
                                  )}
                                  {device.type === "computer" && (
                                    <Mouse fill={"#fff"} height={25} />
                                  )}
                                </div>
                              );
                            }
                          })}
                      </Flex>
                    </Flex>
                    {((loggedIn && loggedIn.admin) ||
                      props.userId === loggedIn._id) && (
                      <Flex
                        display={["none", "flex", "flex", "flex", "flex"]}
                        justifyContent={"flex-end"}
                      >
                        <Edit width={20} />
                      </Flex>
                    )}
                  </Flex>
                </Board>
              )}
              {formEdit && details.id === i && (
                <>
                  <Flex justifyContent={"flex-end"} paddingBottom={"1em"}>
                    <Button
                      size={"sm"}
                      height={"2em"}
                      onClick={() => addNewLog()}
                    >
                      {`X close`}
                    </Button>
                  </Flex>
                  <RenderEditTimelog
                    i={i}
                    handleFormFieldChange={handleFormFieldChange}
                    details={details}
                    devices={props.devices}
                    removeLog={removeLog}
                    setLog={setLog}
                  />
                </>
              )}
            </Box>
          );
        })}
    </>
  );
}
