import React from "react";
import { Link } from "react-router-dom";
import {
  useGlobalState,
  setStorageState,
  setGlobalState,
  getGlobalState
} from "../../store";
import {
  ListContainer,
  StyledDrawer,
  StyledListItem,
  StyledNavigationPanel,
  StyledLogo,
  StyledListWrap,
  StyledFooter,
  StyledList
} from "./styled";

import { BsFillBarChartFill } from "react-icons/bs";

import { FaUserCircle } from "react-icons/fa";
import { AiOutlineLogout } from "react-icons/ai";
import { RiTrelloFill } from "react-icons/ri";
import { Icon } from "@chakra-ui/react";

import { Calendar, Dashboard, Requests, Settings, User } from "./icons";

const Navigation = props => {
  // const [showApproval, setShowApproval] = useState(false)
  const [loggedIn, setLoggedIn] = useGlobalState("loggedIn");
  const [userDetails, setUserDetails] = useGlobalState("editProfile"); //dont remove
  const socket = getGlobalState("socket");
  const [pageClass] = useGlobalState("pageClass");
  // get socket for status
  // const timeLog = getGlobalState('timeLog') //need to get feed
  const [feed, setCalendar] = useGlobalState("calendar"); //dont remove
  const [requests, setRequests] = useGlobalState("requests"); //dont remove
  const users = getGlobalState("users");

  const logout = async () => {
    if (socket) await socket.close();
    setStorageState("loggedIn", null);
    setLoggedIn(null);
  };
  if (!loggedIn || !loggedIn.admin) {
    return (
      <StyledNavigationPanel>
        <StyledDrawer variant="permanent" anchor="left">
          <ListContainer>
            <StyledLogo />
            <StyledListWrap>
              <StyledList>
                <StyledListItem active={pageClass === "dashboard"}>
                  <Link to={"/dashboard"}>
                    <Icon as={Dashboard} />
                    <span>Dashboard</span>
                  </Link>
                </StyledListItem>
                <StyledListItem active={pageClass === "feed"}>
                  <Link to={"/feed"}>
                    {feed && feed.length >= 1 && <i>{feed.length}</i>}
                    <Calendar
                      fill={pageClass === "feed" ? "white" : null}
                      width={"25"}
                      height={"25"}
                    />
                    <span>News Feed</span>
                  </Link>
                </StyledListItem>
                <StyledListItem active={pageClass === "users"}>
                  <Link to={"/users"}>
                    <User
                      fill={pageClass === "users" ? "white" : null}
                      width={"25"}
                      height={"25"}
                    />
                    <span>Ranks</span>
                  </Link>
                </StyledListItem>
                <StyledListItem active={pageClass === "requests"}>
                  <Link to={"/requests"}>
                    {requests && requests.length >= 1 && (
                      <i>{requests.length}</i>
                    )}
                    <Requests
                      fill={pageClass === "requests" ? "white" : null}
                      width={"25"}
                      height={"25"}
                    />
                    <span>Requests</span>
                  </Link>
                </StyledListItem>
                {/* <StyledListItem active={pageClass === "trello"}>
                  <Link to={"/trello"}>
                    <RiTrelloFill
                      fill={pageClass === "trello" ? "white" : "#b9cadc"}
                      size={"1.2em"}
                    />
                    <span>Trello</span>
                  </Link>
                </StyledListItem> */}
              </StyledList>
            </StyledListWrap>
          </ListContainer>
          <StyledFooter>
            <StyledList>
              <StyledListItem>
                <Link border="1px" onClick={() => setUserDetails(true)}>
                  <Icon as={FaUserCircle} />
                </Link>
              </StyledListItem>
              <StyledListItem>
                <Link onClick={() => logout()} border="1px">
                  <Icon as={AiOutlineLogout} />
                </Link>
              </StyledListItem>
            </StyledList>
          </StyledFooter>
        </StyledDrawer>
      </StyledNavigationPanel>
    );
  }

  return (
    <StyledNavigationPanel>
      <StyledDrawer variant="permanent" anchor="left">
        <ListContainer>
          <StyledLogo />
          <StyledListWrap>
            <StyledList>
              <StyledListItem active={pageClass === "dashboard"}>
                <Link to={"/dashboard"}>
                  <Dashboard
                    fill={pageClass === "dashboard" ? "white" : null}
                    width={"25"}
                    height={"25"}
                  />
                  <span>Dashboard</span>
                </Link>
              </StyledListItem>

              {/* 

              <StyledListItem active={pageClass === 'ranks'}>
                <Link to={'/ranks'}>
                  <Ranks
                    fill={pageClass === 'ranks' ? 'white' : null}
                    width={'25'}
                    height={'25'}
                  />
                  <span>Ranks</span>
                </Link>
              </StyledListItem>
              <StyledListItem active={pageClass === 'news'}>
                <Link to={'/news'}>
                  <News
                    fill={pageClass === 'news' ? 'white' : null}
                    width={'25'}
                    height={'25'}
                  />
                  <span>News</span>
                </Link>
              </StyledListItem> */}
              <StyledListItem active={pageClass === "feed"}>
                <Link to={"/feed"}>
                  {feed && feed.length >= 1 && <i>{feed.length}</i>}
                  <Calendar
                    fill={pageClass === "feed" ? "white" : null}
                    width={"25"}
                    height={"25"}
                  />
                  <span>News Feed</span>
                </Link>
              </StyledListItem>
              <StyledListItem active={pageClass === "users"}>
                <Link to={"/users"}>
                  <User
                    fill={pageClass === "users" ? "white" : null}
                    width={"25"}
                    height={"25"}
                  />
                  <span>Ranks</span>
                </Link>
              </StyledListItem>
              <StyledListItem active={pageClass === "reports"}>
                <Link to={"/reports"}>
                  <Icon as={BsFillBarChartFill} />
                  <span>CO2 Log</span>
                </Link>
              </StyledListItem>
              <StyledListItem active={pageClass === "requests"}>
                <Link to={"/requests"}>
                  {requests && requests.length >= 1 && <i>{requests.length}</i>}
                  <Requests
                    fill={pageClass === "requests" ? "white" : null}
                    width={"25"}
                    height={"25"}
                  />
                  <span>Requests</span>
                </Link>
              </StyledListItem>
              {/* <StyledListItem active={pageClass === "trello"}>
                <Link to={"/trello"}>
                  <RiTrelloFill
                    fill={pageClass === "trello" ? "white" : "#b9cadc"}
                    size={"1.2em"}
                  />
                  <span>Trello</span>
                </Link>
              </StyledListItem> */}
            </StyledList>
          </StyledListWrap>
        </ListContainer>
        <StyledFooter>
          <StyledList>
            <StyledListItem>
              <Link border="1px" onClick={() => setUserDetails(true)}>
                <Icon as={Settings} />
              </Link>
            </StyledListItem>
            <StyledListItem>
              <Link onClick={() => logout()} border="1px">
                <Icon as={AiOutlineLogout} />
              </Link>
            </StyledListItem>
          </StyledList>
        </StyledFooter>
      </StyledDrawer>
    </StyledNavigationPanel>
  );
};

export default Navigation;
