import React from "react";
import { ChartLine } from "./styled";
import { colors } from "../../theme";
function Stopwatch({ totalTime, currentTime, currentSeconds, size }) {
  //one day has 86400
  let currentPercentage = (currentTime * 80) / totalTime;
  const currentSecondsPercentage = (currentSeconds * 80) / 60;
  if (currentPercentage > 80) {
    currentPercentage = 80;
  }
  return (
    <ChartLine>
      <svg
        className="circle-chart"
        viewBox="0 0 33.9 33.9"
        width={size || "300"}
        height={size || "300"}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className={"circle-chart__circle"}
          stroke={"#fff2"}
          strokeWidth={2.5}
          strokeDasharray={`${currentSecondsPercentage},100`}
          strokeLinecap="round"
          fill="none"
          cx={16.91549431}
          cy={16.91549431}
          r={15.91549431}
        />
        <circle
          className={"circle-chart__circle"}
          stroke={"#545454"}
          strokeWidth={1.5}
          strokeDasharray="80,100"
          strokeLinecap="round"
          fill="none"
          cx={16.91549431}
          cy={16.91549431}
          r={15.91549431}
        />
        <circle
          className={"circle-chart__circle"}
          stroke={colors.secondary[400]}
          strokeWidth={1.5}
          strokeDasharray={`${currentPercentage},100`}
          strokeLinecap="round"
          fill="none"
          cx={16.91549431}
          cy={16.91549431}
          r={15.91549431}
        />
      </svg>
    </ChartLine>
  );
}

export default Stopwatch;
