import React from "react";
import axios from "axios";
import Dropzone from "react-dropzone";
import { DropContainer } from "./styled";

export default function Upload({ callback, fullCallback }) {
  const uploadFiles = async files => {
    try {
      const file = new Blob([files[0]]); // kind of works and choses stream as content type of file (not request)
      const data = new FormData();
      data.append(
        "file",
        file,
        files[0].name || files[0].type.replace("/", ".")
      );

      const result = await axios.post(`/files`, data);
      if (!result.data) throw new Error("no data");
      //now lets get the image url
      callback && callback(result.data.link);
      fullCallback && fullCallback(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dropzone maxFiles={2} onDrop={acceptedFiles => uploadFiles(acceptedFiles)}>
      {({ getRootProps, getInputProps }) => (
        <DropContainer {...getRootProps()}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop a file here, or click to select files</p>
        </DropContainer>
      )}
    </Dropzone>
  );
}
