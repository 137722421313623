import React, { useState, useEffect } from "react";
import { Button, Box, Flex } from "@chakra-ui/react";

import { useToast } from "@chakra-ui/react";

import {
  setGlobalState,
  setStorageState,
  useGlobalState,
  getGlobalState,
} from "../../store";

export default function ConnectionModal() {
  const toast = useToast();

  // const cancelRef = React.useRef();
  const [connectionError, setConnectionError] =
    useGlobalState("connectionError");
  const socket = getGlobalState("socket");

  const reload = async () => {
    // Handle token expiration
    setStorageState("loggedIn", null);
    setGlobalState("loggedIn", null);
    await socket.close();
    return (window.location = "/auth");
  };

  useEffect(() => {
    const id = "connection-toast";
    if (connectionError && !toast.isActive(id)) {
      toast({
        id,
        position: "bottom",
        isClosable: false,
        duration: null,
        // title: "Connection error.",
        status: "error",
        description: (
          <Flex alignItems={"center"}>
            Changes are not beeing saved, you can wait or try to
            <Button
              size={"sm"}
              colorScheme={"whiteAlpha"}
              onClick={() => {
                reload();
                setConnectionError(false);
              }}
              ml={3}
            >
              Reload
            </Button>
          </Flex>
        ),
      });
    }

    if (!connectionError) {
      toast.closeAll();
    }
  }, [connectionError]);

  return (
    <>
      {/* <AlertDialog
        isOpen={connectionError}
        isCentered
        leastDestructiveRef={cancelRef}
        onClose={() => setConnectionError(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Connection error.
            </AlertDialogHeader>

            <AlertDialogBody>
              Would o like to reset your session and login again?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setConnectionError(false)}>
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  reload();
                  setConnectionError(false);
                }}
                ml={3}
              >
                Proceder
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog> */}
    </>
  );
}
