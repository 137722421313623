import styled, { css } from "styled-components";
import { colors } from "../../theme";
export const HeaderContainer = styled.div`
  && {
    display: block;
    min-height: ${props => `${props.height || 30}vh`};
    z-index: 3;
    position: relative;
    /* width: calc(100vw - 80px); */
    @media screen and (max-width: 768px) {
      width: 100%;
      min-height: ${props =>
        props.minHeight ? `${props.minHeight}px` : "auto"};
      height: ${props => `${props.mheight || 30}vh`};
    }
    &:before {
      min-height: ${props => `${props.height || 30}vh`};
      /* transform: skew(5deg, 0deg); */
      content: " ";
      position: absolute;
      display: block;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 2;
      background: #000;
      transform-origin: bottom left;
      /* animation: gradient 100000s infinite linear; */
      transform: translateZ(0);
      /* transform: skew(0deg, -5deg); */
      clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
      background: url("/images/Pattern_StarsSquad.png");
      background-repeat: repeat;
      background-position: center center;
      ${props =>
        props.image
          ? css`
              background: url("/images/shipPatt.png"),
                radial-gradient(
                  circle,
                  rgba(0, 0, 0, 0.7) 40%,
                  rgba(0, 0, 0, 0.4) 100%
                ),
                url(${props.image});
              background-size: auto, auto, cover;
              background-repeat: repeat, no-repeat, no-repeat;
            `
          : css`
              background-color: ${colors.primary[500]};
            `};

      @media screen and (max-width: 768px) {
        min-height: ${props =>
          props.minHeight ? `${props.minHeight}px` : "auto"};
        height: ${props => `${props.mheight || 30}vh`};
      }
    }

    &:after {
      content: " ";
      position: absolute;
      /* transform: skew(0deg, 2deg); */
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);
      min-height: ${props => `${props.height || 30}vh`};
      width: 100%;
      z-index: 1;
      top: -1vh;
      left: 0;
      background-color: ${colors.secondary[500]};
      @media screen and (max-width: 768px) {
        min-height: ${props =>
          props.minHeight ? `${props.minHeight}px` : "auto"};
        height: ${props => `${props.mheight || 30}vh`};
      }
    }
    @keyframes gradient {
      0% {
        background-position: 0% 1000000px;
      }

      100% {
        background-position: 1000000px 0;
      }
    }
  }
`;
export const HeaderContainerWrap = styled.div`
  && {
    display: flex;
    width: 80%;
    margin: 0 10%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    min-height: ${props => `${props.height || 30}vh`};
    position: absolute;
    z-index: 9;
    @media screen and (max-width: 1160px) {
      width: 95%;
      margin: 0 2%;
    }
    @media screen and (max-width: 768px) {
      justify-content: flex-start;
      margin-top: 2em;
      min-height: ${props =>
        props.minHeight ? `${props.minHeight}px` : "auto"};
      height: ${props => `${props.mheight - 10 || 30}vh`};
    }
  }
`;
