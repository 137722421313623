import React from 'react'

const Settings = props => {
  return (
    <svg
      width={41}
      height={43}
      viewBox="0 0 41 43"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{'setting'}</title>
      <path
        d="M21.187 16.412c-1.503 0-2.91.577-3.977 1.63a5.5 5.5 0 00-1.648 3.93A5.5 5.5 0 0017.21 25.9a5.632 5.632 0 003.977 1.63c1.503 0 2.91-.582 3.976-1.63a5.5 5.5 0 001.649-3.93 5.5 5.5 0 00-1.65-3.93 5.612 5.612 0 00-3.975-1.629zM40.03 26.69l-3.087-2.624a16.733 16.733 0 000-5.417l3.087-2.624a1.498 1.498 0 00.439-1.652l-.043-.122a20.744 20.744 0 00-3.757-6.464l-.085-.099a1.521 1.521 0 00-1.656-.446L31.095 8.6a16.504 16.504 0 00-4.701-2.7l-.741-3.985A1.509 1.509 0 0024.435.708l-.127-.023a21.386 21.386 0 00-7.496 0l-.127.023a1.509 1.509 0 00-1.218 1.206L14.72 5.92a16.705 16.705 0 00-4.668 2.69l-3.86-1.367a1.516 1.516 0 00-1.658.446l-.085.099A20.905 20.905 0 00.693 14.25l-.042.122a1.502 1.502 0 00.439 1.652l3.124 2.652a16.413 16.413 0 00-.217 2.676c0 .901.071 1.802.217 2.675L1.1 26.681a1.498 1.498 0 00-.439 1.652l.043.122a20.798 20.798 0 003.757 6.464l.085.098a1.521 1.521 0 001.657.446l3.86-1.366a16.367 16.367 0 004.669 2.69l.745 4.004c.115.615.6 1.095 1.218 1.206l.128.024c2.478.444 5.016.444 7.495 0l.127-.024a1.509 1.509 0 001.218-1.206l.741-3.985a16.615 16.615 0 004.701-2.7l3.833 1.357c.592.208 1.251.03 1.657-.446l.085-.098a20.905 20.905 0 003.757-6.464l.042-.122a1.51 1.51 0 00-.448-1.643zM21.187 30c-4.488 0-8.125-3.594-8.125-8.029 0-4.435 3.637-8.03 8.125-8.03 4.487 0 8.125 3.595 8.125 8.03 0 4.435-3.638 8.03-8.125 8.03z"
        fill={props.fill || '#C5C5C5'}
        fillRule="nonzero"
      />
    </svg>
  )
}
export default Settings
