import React from 'react'
import { Button, Flex, Box, Icon } from '@chakra-ui/react'
import { FaUserCircle } from 'react-icons/fa'

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton
} from '@chakra-ui/react'
import { Board } from '../components/styled'
import { PlayerValue } from './styled'
import { colors } from '../theme'
import { getGlobalState } from '../store'

export default function ChooseUserModal(props) {
  // const [initiatives, setInitiatives] = useGlobalState('initiatives')
  const types = getGlobalState('userTypes')
  const loggedIn = getGlobalState('loggedIn') //dont remove
  const users = getGlobalState('users') //dont remove
  return (
    <Drawer
      isOpen={true}
      placement="right"
      isFullHeight={true}
      size={'md'}
      onClose={() => props.setOpenModal()}
      // finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent background={colors.primary[500]}>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="0">Select User</DrawerHeader>

        <DrawerBody overflow={'auto'}>
          {users &&
            users.map((user, i) => {
              if (loggedIn._id === user._id) return null
              let thisClient
              if (user.role) {
                thisClient = types.find(c => c._id === user.role) || 'global'
              }

              const isActive = props.selectedUsers.find(u => u === user._id)
              console.log(isActive)
              return (
                <Board
                  onClick={() =>
                    !props.isRunning &&
                    props.setSelectedUsers(
                      isActive
                        ? props.selectedUsers.filter(u => u !== user._id)
                        : [...props.selectedUsers, user._id]
                    )
                  }
                  active={isActive}
                  width={'100%'}
                  key={i}
                >
                  <Flex
                    alignContent={'center'}
                    className={'content'}
                    alignItems={'center'}
                  >
                    <Box marginRight={'1em'}>
                      {user.image ? (
                        <Box
                          borderRadius={'50%'}
                          height={'4em'}
                          width={'4em'}
                          background={`url(${user.image})`}
                          backgroundPosition={'center'}
                          backgroundSize={'cover'}
                        />
                      ) : (
                        <Icon
                          as={FaUserCircle}
                          color={'white'}
                          height={'4em'}
                          width={'4em'}
                        />
                      )}
                    </Box>
                    <div>
                      <PlayerValue>
                        {user.firstName} {user.lastName}
                        <span>{thisClient ? thisClient.name : 'global'}</span>
                      </PlayerValue>
                    </div>
                  </Flex>
                </Board>
              )
            })}
        </DrawerBody>

        <DrawerFooter borderTopWidth="0">
          <Button
            variant="outline"
            colorScheme="primary"
            mr={3}
            onClick={() => props.setOpenModal()}
          >
            Close
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
